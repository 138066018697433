import React, { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider, Portal, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { getWorkatoAutocompleteOptionsByIdentifier } from '../../../../../../store/organisation/integrations/workato/activeOneToOneCampaigns/activeOneToOneCampaigns.selectors';
import { getIfAllConnectionsAuthorized } from '../../../../../../store/organisation/integrations/workato/connections/connections.selectors';
import {
  SixthSensePersonalIntegrationField,
  TSixthSensePersonalConfigurationForm,
} from '../../../../../../store/organisation/integrations/workato/picklists/picklists.types';
import { executeWorkatoRecipeAction } from '../../../../../../store/organisation/integrations/workato/recipes/recipes.actions';
import {
  getIsCurrentIntegrationActive,
  makeGetDefaultSixthSensePersonalRecipeFieldValues,
  makeGetIsRecipeActionLoading,
} from '../../../../../../store/organisation/integrations/workato/recipes/recipes.selectors';
import { IWorkatoRecipe, TRecipeAction } from '../../../../../../store/organisation/integrations/workato/workato.types';
import WorkatoActionButton from '../../builder/controls/WorkatoActionButton';
import { WorkatoCampaignsAutocomplete } from '../../builder/controls/WorkatoCampaignsAutocomplete/WorkatoCampaignsAutocomplete';
import WorkatoInfoTooltip from '../../builder/controls/WorkatoInfoTooltip';
import WorkatoTextField from '../../builder/controls/WorkatoTextfield';
import { getSixthSensePersonalFieldCodeByFormFieldName, mapFormToSixthSenseRecipeFields } from '../helpers';
import WorkatoPickUserAutocomplete from '../../builder/controls/WorkatoPickUserAutocomplete';

import { customPersonalObjectRecipe } from './SixthSenseConstants';
import { sixthSensePersonalSchemaDefaultValues, sixthSensePersonalSchema } from './objectRecipe.schemas';

interface ISixthSensePersonalObjectRecipeConfigurationProps {
  recipe: IWorkatoRecipe;
  isDisabled: boolean;
  buttonContainerRef: MutableRefObject<HTMLElement | null>;
}

export const SixthSensePersonalObjectRecipeConfiguration = ({
  recipe,
  isDisabled,
  buttonContainerRef,
}: ISixthSensePersonalObjectRecipeConfigurationProps): JSX.Element => {
  const dispatch = useDispatch();
  const { id: recipeId, running } = recipe;

  const isRecipeLoadingSelector = useMemo(() => makeGetIsRecipeActionLoading(recipeId), [recipeId]);
  const isRecipeLoading = useSelector(isRecipeLoadingSelector);
  const isAllConnectionsAuthorized = useSelector(getIfAllConnectionsAuthorized);
  const isCurrentIntegrationActive = useSelector(getIsCurrentIntegrationActive);

  const isFormFieldDisabled = !isAllConnectionsAuthorized || isRecipeLoading || running;

  const defaultSixthSensePersonalRecipeFieldValuesSelector = useMemo(
    () => makeGetDefaultSixthSensePersonalRecipeFieldValues(recipeId),
    [recipeId],
  );
  const defaultSixthSenseFormFieldValues = useSelector(defaultSixthSensePersonalRecipeFieldValuesSelector);

  const {
    control,
    reset,
    formState: { isValid },
    handleSubmit,
    watch,
  } = useForm<TSixthSensePersonalConfigurationForm>({
    mode: 'all',
    resolver: yupResolver(sixthSensePersonalSchema),
    defaultValues: sixthSensePersonalSchemaDefaultValues,
    shouldUnregister: true,
  });

  const selectedCampaignId = watch('campaign');

  const campaigns = useSelector(getWorkatoAutocompleteOptionsByIdentifier(recipeId));
  const currentCampaign = campaigns.find(({ id }) => id === selectedCampaignId);
  const currentCampaignTeamId = currentCampaign?.team.id;

  const handleRecipeAction = useCallback(
    (action: TRecipeAction) => {
      handleSubmit(formValues => {
        const isCustomObjRecipe = recipeId === customPersonalObjectRecipe.id;
        const fields = mapFormToSixthSenseRecipeFields(formValues, formFieldName =>
          getSixthSensePersonalFieldCodeByFormFieldName(formFieldName, isCustomObjRecipe),
        );

        dispatch(
          executeWorkatoRecipeAction({
            recipeId,
            action,
            fields: action === 'start' ? fields : undefined,
          }),
        );
      })();
    },
    [dispatch, handleSubmit, recipeId],
  );

  useEffect(() => {
    if (defaultSixthSenseFormFieldValues) {
      reset(defaultSixthSenseFormFieldValues, { keepErrors: true });
    }
  }, [reset, defaultSixthSenseFormFieldValues]);

  return (
    <Box m={3}>
      <Divider variant="middle" />
      <Box mt={3}>
        <Typography className="H4-Dark">Configuration</Typography>

        <WorkatoTextField<TSixthSensePersonalConfigurationForm>
          name={SixthSensePersonalIntegrationField.Segment}
          control={control}
          disabled={isFormFieldDisabled}
          description="When an account that is part of ..."
          placeholder="type a 6Sense segment name"
          tooltip={
            <WorkatoInfoTooltip
              title={
                <Typography fontSize={16}>
                  This must be an exact match with the name of your segment in 6Sense.
                </Typography>
              }
            />
          }
        />
        <WorkatoTextField<TSixthSensePersonalConfigurationForm>
          name={SixthSensePersonalIntegrationField.Stage}
          control={control}
          disabled={isFormFieldDisabled}
          description="and moves into ..."
          placeholder="type a 6Sense stage name"
          tooltip={
            <WorkatoInfoTooltip
              title={
                <Typography fontSize={16}>
                  You may enter multiple stages as a comma-separated list. <br /> Example: &ldquo;Stage 1, Stage
                  2&ldquo; would trigger when accounts move into Stage 1 or Stage 2.
                </Typography>
              }
            />
          }
        />
        <WorkatoTextField<TSixthSensePersonalConfigurationForm>
          name={SixthSensePersonalIntegrationField.JobTitles}
          control={control}
          disabled={isFormFieldDisabled}
          description="find all contacts that contain ..."
          placeholder="type recipient job title"
          tooltip={
            <WorkatoInfoTooltip
              title={
                <Typography fontSize={16}>
                  You may enter one value. Any contacts from your Salesforce system whose job titles contain this value
                  will be added.
                  <br /> Example: &ldquo;Marketing&ldquo; adds &ldquo;VP Marketing&ldquo; and &ldquo;Director of
                  Marketing&ldquo;
                </Typography>
              }
            />
          }
        />
        <WorkatoCampaignsAutocomplete<TSixthSensePersonalConfigurationForm>
          isOneToOne
          description="from that account, and add those contacts to ..."
          disabled={isFormFieldDisabled}
          autocompleteIdentifier={recipeId}
          name={SixthSensePersonalIntegrationField.Campaign}
          control={control}
          placeholder="choose an Alyce 1:1 campaign"
          tooltip={
            <WorkatoInfoTooltip
              title={
                <Typography fontSize={16}>Contacts will only be added to the specified 1:1 campaign once.</Typography>
              }
            />
          }
        />

        <WorkatoPickUserAutocomplete
          control={control}
          name={SixthSensePersonalIntegrationField.Email}
          currentCampaignTeamId={currentCampaignTeamId}
          description="select a Send as person"
          disabled={isFormFieldDisabled}
          placeholder="send as"
        />

        <Portal container={buttonContainerRef.current}>
          <WorkatoActionButton
            recipeId={recipeId}
            isRecipeRunning={running}
            disabled={isDisabled || !isValid}
            onSubmitWorkatoForm={handleRecipeAction}
            isIntegrationActive={isCurrentIntegrationActive}
          />
        </Portal>
      </Box>
    </Box>
  );
};
