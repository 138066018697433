export const GIFT_BREAKDOWN_LOAD_REQUEST = 'GIFT_BREAKDOWN_LOAD_REQUEST';
export const GIFT_BREAKDOWN_LOAD_SUCCESS = 'GIFT_BREAKDOWN_LOAD_SUCCESS';
export const GIFT_BREAKDOWN_LOAD_FAILS = 'GIFT_BREAKDOWN_LOAD_FAILS';

export const GIFT_BREAKDOWN_TABLE_LOAD_REQUEST = 'GIFT_BREAKDOWN_TABLE_LOAD_REQUEST';
export const GIFT_BREAKDOWN_TABLE_LOAD_SUCCESS = 'GIFT_BREAKDOWN_TABLE_LOAD_SUCCESS';
export const GIFT_BREAKDOWN_TABLE_LOAD_FAILS = 'GIFT_BREAKDOWN_TABLE_LOAD_FAILS';

export const GIFT_DOWNLOAD_REPORT_REQUEST = 'GIFT_DOWNLOAD_REPORT_REQUEST';
export const GIFT_DOWNLOAD_REPORT_SUCCESS = 'GIFT_DOWNLOAD_REPORT_SUCCESS';
export const GIFT_DOWNLOAD_REPORT_FAIL = 'GIFT_DOWNLOAD_REPORT_FAIL';

export const GIFT_BREAKDOWN_DOWNLOAD_REPORT_REQUEST = 'GIFT_BREAKDOWN_DOWNLOAD_REPORT_REQUEST';
export const GIFT_BREAKDOWN_DOWNLOAD_REPORT_SUCCESS = 'GIFT_BREAKDOWN_DOWNLOAD_REPORT_SUCCESS';
export const GIFT_BREAKDOWN_DOWNLOAD_REPORT_FAIL = 'GIFT_BREAKDOWN_DOWNLOAD_REPORT_FAIL';

export const GIFT_DOWNLOAD_RECEIPTS_REQUEST = 'GIFT_DOWNLOAD_RECEIPTS_REQUEST';
export const GIFT_DOWNLOAD_RECEIPTS_SUCCESS = 'GIFT_DOWNLOAD_RECEIPTS_SUCCESS';
export const GIFT_DOWNLOAD_RECEIPTS_FAIL = 'GIFT_DOWNLOAD_RECEIPTS_FAIL';

export const GIFT_BREAKDOWN_CLEAR = 'GIFT_BREAKDOWN_CLEAR';
