import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Features } from '@alycecom/modules';

import { IConfigurableIntegration, Integrations } from '../models/IntegrationsModels';
import { loadOAuthState } from '../../../../../store/organisation/integrations/dynamics/dynamics.actions';
import {
  INTEGRATION_STATUS_ATTENTION,
  INTEGRATION_STATUS_CONNECTED,
  INTEGRATION_STATUS_LOCKED,
} from '../../../../../constants/organizationSettings.constants';
import dynamicsIcon from '../../../../../../../assets/images/microsoft.svg';
import {
  getOAuthIsLoading,
  getOAuthState,
} from '../../../../../store/organisation/integrations/dynamics/dynamics.selectors';
import { ConnectionState } from '../../../../../store/organisation/integrations/dynamics/dynamics.types';
import { useOpenIntegration } from '../../../../../hooks/useOpenIntegration';
import { IntegrationCard } from '../../IntegrationCard/IntegrationCard';

const description =
  'Alyce for Dynamics is a Dynamics AppExchange certified integration that allows Dynamics users to visualize all of their Alyce gifting details and data within Dynamics as well as send Alyce gifts without leaving their Dynamics instance.';

const DynamicsIntegration = ({ url }: IConfigurableIntegration): JSX.Element => {
  const dispatch = useDispatch();
  const oAuthState = useSelector(getOAuthState);
  const isLoading = useSelector(getOAuthIsLoading);
  const dynamicsAvailable = useSelector(
    useMemo(() => Features.selectors.hasFeatureFlag(Features.FLAGS.DYNAMICS_INTEGRATION), []),
  );

  useEffect(() => {
    if (dynamicsAvailable) {
      dispatch(loadOAuthState());
    }
  }, [dynamicsAvailable, dispatch]);

  const getDynamicsIntegrationStatus = useCallback(() => {
    if (!dynamicsAvailable) {
      return INTEGRATION_STATUS_LOCKED;
    }

    if (oAuthState === ConnectionState.Disconnected) {
      return INTEGRATION_STATUS_ATTENTION;
    }
    if (oAuthState === ConnectionState.Connected) {
      return INTEGRATION_STATUS_CONNECTED;
    }
    return null;
  }, [oAuthState, dynamicsAvailable]);

  const selectIntegration = useOpenIntegration(url);

  return (
    <IntegrationCard
      title="Dynamics"
      isLoading={isLoading}
      logoSrc={dynamicsIcon}
      description={description}
      status={getDynamicsIntegrationStatus()}
      shouldGoToMarketplace={false}
      open={() => selectIntegration(Integrations.Dynamics)}
    />
  );
};
export default memo(DynamicsIntegration);
