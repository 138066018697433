import React, { memo, useEffect } from 'react';
import { Box } from '@mui/material';
import { AlyceLoading, DashboardIcon, LinkButton } from '@alycecom/ui';
import { useRouting } from '@alycecom/hooks';
import { CampaignType, useGetCampaignsQuery } from '@alycecom/services';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import {
  getInfluitiveIsIdle,
  getInfluitiveWebhook,
} from '../../../../../store/organisation/integrations/influitive/influitive.selectors';
import { loadOrganizationInfluitiveIntegration } from '../../../../../store/organisation/integrations/influitive/influitive.actions';
import DashboardLayout from '../../../../../../../components/Dashboard/Shared/DashboardLayout';
import { INFLUITIVE_CAMPAIGNS_MAX_LIST } from '../../../../../../InfluitiveModule/constants/influitive.constants';

import OrganizationIntegrationInfluitive from './InfluitiveIntegrationConfiguration/OrganizationIntegrationInfluitive';
import InfluitiveCampaignsManagement from './InfluitiveCampaignsManagement/InfluitiveCampaignsManagement';

const useStyles = makeStyles(({ spacing }) => ({
  icon: {
    marginRight: spacing(1),
    fontSize: '1rem',
  },
  divider: {
    margin: spacing(5, 0),
  },
}));

interface InfluitiveIntegrationManagerProps {
  parentUrl: string;
}

const InfluitiveIntegrationManager = ({ parentUrl }: InfluitiveIntegrationManagerProps) => {
  const classes = useStyles();
  const go = useRouting();
  const dispatch = useDispatch();
  const isInfluitiveIdle = useSelector(getInfluitiveIsIdle);
  const webhook = useSelector(getInfluitiveWebhook);
  const { isFetching, data: campaignsResponse } = useGetCampaignsQuery(
    {
      types: [CampaignType.Influitive] as CampaignType[],
      pagination: {
        limit: INFLUITIVE_CAMPAIGNS_MAX_LIST,
        offset: 0,
      },
    },
    { refetchOnMountOrArgChange: true },
  );
  const campaigns = campaignsResponse?.data || [];
  const isCampaignsConnectionAvailable = !!webhook.webhookUrl && campaigns.length > 0;

  useEffect(() => {
    if (isInfluitiveIdle) {
      dispatch(loadOrganizationInfluitiveIntegration());
    }
  }, [dispatch, isInfluitiveIdle]);

  return (
    <DashboardLayout>
      <Box mb={3}>
        <LinkButton onClick={() => go(parentUrl)}>
          <DashboardIcon icon="arrow-left" color="link" className={classes.icon} />
          Back to all integrations
        </LinkButton>
      </Box>
      {isFetching ? (
        <AlyceLoading isLoading />
      ) : (
        <>
          {isCampaignsConnectionAvailable ? (
            <InfluitiveCampaignsManagement isLoading={isFetching} getCampaignsResponse={campaignsResponse} />
          ) : (
            <OrganizationIntegrationInfluitive />
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default memo(InfluitiveIntegrationManager);
