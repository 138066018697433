import { User } from '@alycecom/modules';
import { useGetTeamMembersByTeamId } from '@alycecom/modules/dist/Shared/Teams/hooks/useGetTeamMembersByTeamId';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { useSelector } from 'react-redux';

interface IWorkatoPickUserAutocompleteProps<T extends FieldValues> {
  description: string;
  currentCampaignTeamId: number | undefined;
  name: Path<T>;
  control: Control<T>;
  disabled?: boolean;
  tooltip?: JSX.Element;
  placeholder?: string;
}

const useStyles = makeStyles(() => ({
  autocomplete: {
    width: '35%',
    marginLeft: '20px',
  },
  autocompleteInput: {
    height: 48,
  },
}));

const WorkatoPickUserAutocomplete: <T extends FieldValues>(
  props: IWorkatoPickUserAutocompleteProps<T>,
) => React.ReactElement<IWorkatoPickUserAutocompleteProps<T>> = ({
  currentCampaignTeamId,
  description,
  control,
  name,
  disabled = false,
  tooltip,
  placeholder,
}) => {
  const classes = useStyles();

  const {
    fieldState: { error },
    field: { value, onChange },
  } = useController({ control, name });
  const { useGetAll } = useGetTeamMembersByTeamId(currentCampaignTeamId);

  const currentUserEmail = useSelector(User.selectors.getUserEmail);
  const teamMembers = useGetAll();

  const teamMembersOptions = teamMembers.map(({ email }) => email);

  const customTeamMemberRenderOptions = useCallback(
    (teamMemberEmail: string) => {
      const member = teamMembers.find(({ email }) => email === teamMemberEmail);
      if (!member) return '';

      const isCurrentUser = teamMemberEmail === currentUserEmail;
      return isCurrentUser
        ? `Myself (${member.firstName} ${member.lastName})`
        : `${member.firstName} ${member.lastName}`;
    },
    [currentUserEmail, teamMembers],
  );

  return (
    <Box mt={1}>
      <Typography>{description}</Typography>
      <Box mt={2} display="flex">
        <Autocomplete<string, false>
          value={value as string}
          className={classes.autocomplete}
          options={teamMembersOptions}
          getOptionLabel={customTeamMemberRenderOptions}
          disabled={disabled}
          onChange={(e, data) => {
            onChange(data);
          }}
          renderInput={params => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                className: classes.autocompleteInput,
              }}
              error={!!error}
              helperText={error && error.message}
              placeholder={placeholder}
              variant="outlined"
            />
          )}
        />
        {tooltip}
      </Box>
    </Box>
  );
};

export default WorkatoPickUserAutocomplete;
