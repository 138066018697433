import { createReducer } from 'redux-act';

import { DEFAULT_ROI_PERIOD, DEFAULT_ROI_PERIOD_NAME } from '../../utils';
import { TActiveFilterSelection } from '../../utils/roiTypes';

import { resetRoiFilters, setRoiFilters } from './filters.actions';
import { TRoiFiltersState } from './filters.types';

export const initialState: TRoiFiltersState = {
  period: DEFAULT_ROI_PERIOD,
  periodName: DEFAULT_ROI_PERIOD_NAME,
  teamIds: [],
  campaignIds: [],
  teamMemberIds: [],
  activeFilterType: TActiveFilterSelection.Teams,
};

export const filters = createReducer<TRoiFiltersState>({}, initialState);

filters.on(setRoiFilters, (state, payload) => ({
  ...state,
  ...payload,
}));

filters.on(resetRoiFilters, () => ({ ...initialState }));
