import React, { memo } from 'react';
import { FormControl, FormHelperText, MenuItem, Select, Theme } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BudgetCreateField, DeductFrom, TBudgetCreateParams } from '@alycecom/services';

const styles = {
  select: {
    '& .MuiSelect-select.Mui-disabled': {
      backgroundColor: ({ palette }: Theme) => palette.common.white,
      WebkitTextFillColor: ({ palette }: Theme) => palette.grey.main,
    },
  },
  emphasized: {
    display: 'inline',
    fontWeight: 'bold',
  },
} as const;

interface IPauseGiftingOnSelectorProps {
  control: Control<TBudgetCreateParams>;
  error?: string;
}

const DeductSendAsFromSelector = ({ control, error }: IPauseGiftingOnSelectorProps): JSX.Element => (
  <Controller
    name={BudgetCreateField.DeductFrom}
    control={control}
    render={({ field: { onChange, value } }) => (
      <FormControl fullWidth variant="outlined" error={!!error}>
        <Select
          labelId="DeductFrom.Select.Label"
          id="DeductFrom.Select.Label"
          value={value ?? DeductFrom.SENDER}
          onChange={onChange}
          data-testid="DeductFrom.Select"
          IconComponent={ExpandMoreIcon}
          sx={styles.select}
        >
          <MenuItem key={DeductFrom.SENDER} data-testid={`DeductFrom.${DeductFrom.SENDER}`} value={DeductFrom.SENDER}>
            The budget of the user who sends the gift
          </MenuItem>
          <MenuItem
            key={DeductFrom.SEND_AS}
            data-testid={`DeductFrom.${DeductFrom.SEND_AS}`}
            value={DeductFrom.SEND_AS}
          >
            The budget of the user the gift is sent as
          </MenuItem>
        </Select>
        {!!error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    )}
  />
);
export default memo(DeductSendAsFromSelector);
