import React, { memo } from 'react';
import { Box } from '@mui/material';

import RevenueImpactDashboardAccess from './RevenueImpactDashboardAccess';
import AlyceCreatedPipeline from './AlyceCreatedPipeline';

const DashboardSettings = () => (
  <Box p={3}>
    <Box>
      <RevenueImpactDashboardAccess />
    </Box>
    <Box>
      <AlyceCreatedPipeline />
    </Box>
  </Box>
);

export default memo(DashboardSettings);
