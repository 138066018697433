import React, { memo } from 'react';
import { Button } from '@alycecom/ui';

import { useInfluitiveNavigation } from '../../../../../../../InfluitiveModule/hooks/useInfluitiveNavigation';

import StepToConnect from './StepToConnect';

const CreateInfluitiveCampaign = () => {
  const influitiveNavigation = useInfluitiveNavigation();
  return (
    <StepToConnect
      id="CreateInfluitiveCampaign"
      title="3. Create Influitive campaign"
      description="This campaign will not be available in Gifting flow. Gifts will be sent automatically"
    >
      <Button
        variant="contained"
        color="primary"
        data-testid="CreateInfluitiveCampaign.CreateCampaign"
        onClick={influitiveNavigation.goToCampaignCreate}
      >
        Create Influitive Campaign
      </Button>
    </StepToConnect>
  );
};

export default memo(CreateInfluitiveCampaign);
