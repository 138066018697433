import { array, boolean, number, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GiftActions } from '@alycecom/modules';

import { IInfluitiveCreateCampaignPayload } from '../types/Influitive.types';
import { mapGiftActionsFomGiftOption } from '../helpers/influitive.helpers';
import { ProductTypes } from '../../SettingsModule/store/settings.types';

import { InfluitiveBlankMessage } from './message.constants';

export enum InfluitivePages {
  CREATE_CAMPAIGN = '/influitive/campaign/create',
  INFLUITIVE_SETTINGS = '/settings/organization/integrations/influitive',
}

export const InfluitiveCreateCampaignSchema = object().shape({
  team_id: number().required().default(null),
  name: string().required().min(3).max(250).default(''),
  owner_id: number().required(),
  countryIds: array().label('Campaign country').of(number()).min(1).required().default([]),
  team_member_ids: array().required().of(number()).default([]),
  product_type_id: number().required().default(ProductTypes.eGift),
  gift_actions: object()
    .shape({
      accept: boolean(),
      exchange: boolean(),
      donate: boolean(),
    })
    .default(mapGiftActionsFomGiftOption(GiftActions.AcceptOrExchange)),
  messaging: object().shape({
    id: number().default(InfluitiveBlankMessage.templateId),
    isDefault: boolean().default(false),
    subject: string().label('Message subject').required().default(InfluitiveBlankMessage.subject).min(2).max(255),
    message: string().label('Your message').default(InfluitiveBlankMessage.message).min(25).max(1000),
    name: string().default(InfluitiveBlankMessage.name).min(2).max(255),
  }),
  product_type_ids: array().required().of(number()).default([]),
});

export const InfluitiveCreateCampaignResolver = yupResolver(InfluitiveCreateCampaignSchema);

export const InfluitiveCreateCampaignDefaults = InfluitiveCreateCampaignSchema.getDefault() as IInfluitiveCreateCampaignPayload;

export const INFLUITIVE_GIFT_ACTIONS_LIST = [
  {
    id: GiftActions.AcceptOrExchange,
    label: 'Accept or Exchange',
  },
  {
    id: GiftActions.OnlyAccept,
    label: 'Only Accept',
  },
];

export const INFLUITIVE_CAMPAIGNS_LIMIT = 1;

export const INFLUITIVE_CAMPAIGNS_MAX_LIST = 10;
