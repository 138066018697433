import { GlobalMessage, MessageType } from '@alycecom/services';
import { Link, Typography } from '@mui/material';
import { pipe } from 'ramda';
import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSalesforceIntegration } from '../../modules/SettingsModule';
import { showIntegrationsAlert } from '../../store/common/notifications/notifications.actions';
import { getIsIntegrationAlertShowed } from '../../store/common/notifications/notifications.selectors';

const WithIntegrationAlerts: FC<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();
  const isIntegrationAlertsShowed = useSelector(getIsIntegrationAlertShowed);

  const { error } = useSalesforceIntegration({ fetchStateOnly: true });
  const { showGlobalMessage } = GlobalMessage.useGlobalMessage();

  const showMessageOnce = useCallback(
    () =>
      pipe(
        () =>
          showGlobalMessage({
            type: MessageType.Error,
            icon: ['fas', 'exclamation-triangle'],
            text: (
              <Typography>
                Your Organization&apos;s Salesforce Integration has been disconnected. Please reach out to your Admin or{' '}
                <Link variant="body1" sx={{ color: 'inherit' }} display="inline" href="mailto:support@alyce.com">
                  support@alyce.com
                </Link>{' '}
                for assistance.
              </Typography>
            ),
          }),
        () => dispatch(showIntegrationsAlert()),
      )(),
    [showGlobalMessage, dispatch],
  );

  useEffect(() => {
    if (isIntegrationAlertsShowed === false && error !== null) {
      showMessageOnce();
    }
  }, [showMessageOnce, error, isIntegrationAlertsShowed]);

  return <>{children}</>;
};

export default WithIntegrationAlerts;
