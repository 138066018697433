export interface IOAuthStateResponse {
  state: ConnectionState;
  message: string | null;
  connectedBy: string | null;
  connectedAt: string | null;
}

export interface OAuthConnectionEvent {
  state: ConnectionState;
  message: string;
}

export interface DynamicsStartConnectionResponse {
  link: string;
}

export enum ConnectionState {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Error = 'error',
  NotCreated = 'notCreated',
}
