import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';

import { IInfluitiveCredentials } from './influitive.types';

export const connectAlyceAndInfluitiveFormErrors: IInfluitiveCredentials = {
  influitiveAuthorizationToken: 'Token should be between 40 and 46 characters.',
  influitiveXOrgId: 'Org ID should be 36 characters.',
};

export const connectAlyceAndInfluitiveFormSchema = object().shape({
  influitiveAuthorizationToken: string()
    .required(connectAlyceAndInfluitiveFormErrors.influitiveAuthorizationToken)
    .label('Influitive authorization token')
    .min(40, connectAlyceAndInfluitiveFormErrors.influitiveAuthorizationToken)
    .max(46, connectAlyceAndInfluitiveFormErrors.influitiveAuthorizationToken)
    .default(''),
  influitiveXOrgId: string()
    .required(connectAlyceAndInfluitiveFormErrors.influitiveXOrgId)
    .label('Influitive authorization token')
    .min(36, connectAlyceAndInfluitiveFormErrors.influitiveXOrgId)
    .max(36, connectAlyceAndInfluitiveFormErrors.influitiveXOrgId)
    .default(''),
});

export const connectAlyceAndInfluitiveFormResolver = yupResolver(connectAlyceAndInfluitiveFormSchema);

export const connectAlyceAndInfluitiveFormDefaults = connectAlyceAndInfluitiveFormSchema.getDefault() as IInfluitiveCredentials;
