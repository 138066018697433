import { Epic } from 'redux-observable';
import { catchError, debounceTime, map, switchMap, mergeMap } from 'rxjs/operators';
import { ofType, queryParamsBuilder } from '@alycecom/utils';
import { handleError, handlers } from '@alycecom/services';

import { fetchGiftsWithStoredFilters } from '../gifts.actions';

import { IGiftsBreakdownResponse } from './giftsBreakdown.types';
import {
  disableGifts,
  expireGifts,
  fetchGiftsFail,
  fetchGiftsRequest,
  fetchGiftsSuccess,
  resetSelection,
  unexpireGifts,
} from './giftsBreakdown.actions';

export const loadCampaignManagementListEpic: Epic = (action$, state$, { apiService }) =>
  action$.pipe(
    ofType(fetchGiftsRequest),
    debounceTime(500),
    switchMap(({ payload }) =>
      apiService.get(`/api/v1/gifts?${queryParamsBuilder({ ...payload })}`, {}, true).pipe(
        map(({ data: gifts, pagination, meta }: IGiftsBreakdownResponse) =>
          fetchGiftsSuccess({ gifts, pagination, meta }),
        ),
        catchError(handleError(handlers.handleAnyError(fetchGiftsFail))),
      ),
    ),
  );

export const disableGiftsEpic: Epic = (action$, state$, { apiService }) =>
  action$.pipe(
    ofType(disableGifts.pending),
    switchMap(({ payload }) =>
      apiService
        .post(
          '/api/v1/gifts/bulk/disable',
          {
            body: {
              giftIds: payload.giftIds,
            },
          },
          true,
        )
        .pipe(
          mergeMap(() => [disableGifts.fulfilled, fetchGiftsWithStoredFilters(), resetSelection()]),
          catchError(handleError(handlers.handleAnyError(disableGifts.rejected()))),
        ),
    ),
  );

export const expireGiftsEpic: Epic = (action$, state$, { apiService }) =>
  action$.pipe(
    ofType(expireGifts.pending),
    switchMap(({ payload }) =>
      apiService
        .post(
          '/api/v1/gifts/bulk/expire',
          {
            body: {
              giftIds: payload.giftIds,
            },
          },
          true,
        )
        .pipe(
          mergeMap(() => [expireGifts.fulfilled, fetchGiftsWithStoredFilters(), resetSelection()]),
          catchError(handleError(handlers.handleAnyError(expireGifts.rejected()))),
        ),
    ),
  );

export const unexpireGiftsEpic: Epic = (action$, state$, { apiService }) =>
  action$.pipe(
    ofType(unexpireGifts.pending),
    switchMap(({ payload }) =>
      apiService
        .post(
          '/api/v1/gifts/bulk/unexpire',
          {
            body: {
              giftIds: payload.giftIds,
            },
          },
          true,
        )
        .pipe(
          mergeMap(() => [unexpireGifts.fulfilled, fetchGiftsWithStoredFilters(), resetSelection()]),
          catchError(handleError(handlers.handleAnyError(unexpireGifts.rejected()))),
        ),
    ),
  );

export const giftsBreakdownEpics = [
  loadCampaignManagementListEpic,
  disableGiftsEpic,
  expireGiftsEpic,
  unexpireGiftsEpic,
];
