import { combineReducers } from 'redux';

import { giftsBreakdown, IGiftsManagementState } from './giftsBreakdown/giftsBreakdown.reducer';
import { giftsFilters } from './filters/filters.reducer';
import { IGiftsFilters } from './filters/filters.types';

export interface IGiftsManagementStateAll {
  giftsBreakdown: IGiftsManagementState;
  giftsFilters: IGiftsFilters;
}

const gifts = combineReducers<IGiftsManagementStateAll>({
  giftsBreakdown,
  giftsFilters,
});

export default gifts;
