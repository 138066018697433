import React, { memo, useCallback, useState } from 'react';
import { Button } from '@alycecom/ui';
import { not } from 'ramda';
import { Box, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ConnectAlyceAndInfluitive from '../InfluitiveIntegrationConfiguration/ConnectAlyceAndInfluitive';
import CopyAlyceWebhook from '../InfluitiveIntegrationConfiguration/CopyAlyceWebhook';
import CreateInfluitiveCampaign from '../InfluitiveIntegrationConfiguration/CreateInfluitiveCampaign';

import InfluitiveCampaignsTable, { InfluitiveCampaignsTableProps } from './InfluitiveCampaignsTable';

interface InfluitiveCampaignsManagementProps extends InfluitiveCampaignsTableProps {}

const useStyles = makeStyles(({ spacing, palette }) => ({
  divider: {
    margin: spacing(5, 0),
  },
  influitiveIntegration: {
    marginBottom: spacing(4),
    fontSize: '1.5rem',
    color: palette.text.primary,
    fontWeight: 600,
  },
  doneButton: {
    minWidth: 204,
  },
  influitiveCampaignTitle: {
    fontSize: '1.5rem',
    color: palette.text.primary,
    fontWeight: 600,
  },
  influitiveCampaignDescription: {
    color: palette.grey.main,
    lineHeight: 1.5,
    marginTop: spacing(1),
    marginBottom: spacing(2),
  },
  createInfluitiveCampaign: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
}));

const InfluitiveCampaignsManagement = (props: InfluitiveCampaignsManagementProps) => {
  const classes = useStyles();
  const [isOpenIntegrationDetails, setIsOpenIntegrationDetails] = useState(false);
  const toggleOpenIntegrationDetails = useCallback(() => setIsOpenIntegrationDetails(not), []);

  return (
    <>
      {isOpenIntegrationDetails ? (
        <Box>
          <ConnectAlyceAndInfluitive />
          <Divider className={classes.divider} color="primary.superLight" />
          <CopyAlyceWebhook />
          <Divider className={classes.divider} color="primary.superLight" />
          <Box className={classes.createInfluitiveCampaign}>
            <CreateInfluitiveCampaign />
            <Box>
              <Button
                className={classes.doneButton}
                variant="contained"
                color="secondary"
                onClick={toggleOpenIntegrationDetails}
              >
                Done
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography className={classes.influitiveIntegration}>Influitive Integration</Typography>
          <Button variant="contained" color="secondary" onClick={toggleOpenIntegrationDetails}>
            View details & create new campaign
          </Button>
        </Box>
      )}
      <Box>
        <Divider className={classes.divider} color="primary.superLight" />
        <Typography className={classes.influitiveCampaignTitle}>Current Influitive campaign(s)</Typography>
        <Typography className={classes.influitiveCampaignDescription}>
          Influitive campaigns can only be managed via this page. Only gifts automatically ordered through Influitive
          will be sent through these campaigns.
        </Typography>
        <InfluitiveCampaignsTable {...props} />
      </Box>
    </>
  );
};

export default memo(InfluitiveCampaignsManagement);
