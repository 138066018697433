export const SWAG_PHYSICAL_CODES_GENERATE_FLOW_WIZARD_REQUEST = 'SWAG_PHYSICAL_CODES_GENERATE_FLOW_WIZARD_REQUEST';

export const SWAG_PHYSICAL_CODES_CHANGE_STEP = 'SWAG_PHYSICAL_CODES_CHANGE_STEP';
export const SWAG_PHYSICAL_CODES_SET_STEP_DATA = 'SWAG_PHYSICAL_CODES_SET_STEP_DATA';

export const SWAG_PHYSICAL_CAMPAIGN_DATA_REQUEST = 'SWAG_PHYSICAL_CAMPAIGN_DATA_REQUEST';
export const SWAG_PHYSICAL_CAMPAIGN_DATA_SUCCESS = 'SWAG_PHYSICAL_CAMPAIGN_DATA_SUCCESS';
export const SWAG_PHYSICAL_CAMPAIGN_DATA_FAIL = 'SWAG_PHYSICAL_CAMPAIGN_DATA_FAIL';

export const SWAG_PHYSICAL_GENERATE_CODES_REQUEST = 'SWAG_PHYSICAL_GENERATE_CODES_REQUEST';
export const SWAG_PHYSICAL_GENERATE_CODES_SUCCESS = 'SWAG_PHYSICAL_GENERATE_CODES_SUCCESS';
export const SWAG_PHYSICAL_GENERATE_CODES_FAIL = 'SWAG_PHYSICAL_GENERATE_CODES_FAIL';
