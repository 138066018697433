export const SWAG_SELECT_WIZARD_INIT = 'SWAG_SELECT_WIZARD_INIT';

export const SWAG_SELECT_SKIP_STEP = 'SWAG_SELECT_SKIP_STEP';
export const SWAG_SELECT_CHANGE_STEP = 'SWAG_SELECT_CHANGE_STEP';
export const SWAG_SELECT_SET_STEP_DATA = 'SWAG_SELECT_SET_STEP_DATA';

export const SWAG_SELECT_CREATE_CAMPAIGN_REQUEST = 'SWAG_SELECT_CREATE_CAMPAIGN_REQUEST';
export const SWAG_SELECT_CREATE_CAMPAIGN_SUCCESS = 'SWAG_SELECT_CREATE_CAMPAIGN_SUCCESS';
export const SWAG_SELECT_CREATE_CAMPAIGN_FAIL = 'SWAG_SELECT_CREATE_CAMPAIGN_FAIL';

export const SWAG_SELECT_LOAD_CAMPAIGN_REQUEST = 'SWAG_SELECT_LOAD_CAMPAIGN_REQUEST';

export const SWAG_SELECT_SET_CAMPAIGN_TYPE = 'SWAG_SELECT_SET_CAMPAIGN_TYPE';

export const SWAG_SELECT_UPDATE_CAMPAIGN_NAME_REQUEST = 'SWAG_SELECT_UPDATE_CAMPAIGN_NAME_REQUEST';
export const SWAG_SELECT_UPDATE_CAMPAIGN_NAME_SUCCESS = 'SWAG_SELECT_UPDATE_CAMPAIGN_NAME_SUCCESS';
export const SWAG_SELECT_UPDATE_CAMPAIGN_NAME_FAIL = 'SWAG_SELECT_UPDATE_CAMPAIGN_NAME_FAIL';

export const SWAG_SELECT_UPDATE_CAMPAIGN_OWNERSHIP_REQUEST = 'SWAG_SELECT_UPDATE_CAMPAIGN_OWNERSHIP_REQUEST';
export const SWAG_SELECT_UPDATE_CAMPAIGN_OWNERSHIP_SUCCESS = 'SWAG_SELECT_UPDATE_CAMPAIGN_OWNERSHIP_SUCCESS';
export const SWAG_SELECT_UPDATE_CAMPAIGN_OWNERSHIP_FAIL = 'SWAG_SELECT_UPDATE_CAMPAIGN_OWNERSHIP_FAIL';

export const SWAG_SELECT_UPDATE_CAMPAIGN_VENDOR_REQUEST = 'SWAG_SELECT_UPDATE_CAMPAIGN_VENDOR_REQUEST';
export const SWAG_SELECT_UPDATE_CAMPAIGN_VENDOR_SUCCESS = 'SWAG_SELECT_UPDATE_CAMPAIGN_VENDOR_SUCCESS';
export const SWAG_SELECT_UPDATE_CAMPAIGN_VENDOR_FAIL = 'SWAG_SELECT_UPDATE_CAMPAIGN_VENDOR_FAIL';

export const SWAG_SELECT_CLEAR_DATA_ON_CLOSE_SIDEBAR = 'SWAG_SELECT_CLEAR_DATA_ON_CLOSE_SIDEBAR';

export const SWAG_SELECT_UPDATE_CAMPAIGN_BUDGET_REQUEST = 'SWAG_SELECT_UPDATE_CAMPAIGN_BUDGET_REQUEST';
export const SWAG_SELECT_UPDATE_CAMPAIGN_BUDGET_SUCCESS = 'SWAG_SELECT_UPDATE_CAMPAIGN_BUDGET_SUCCESS';
export const SWAG_SELECT_UPDATE_CAMPAIGN_BUDGET_FAIL = 'SWAG_SELECT_UPDATE_CAMPAIGN_BUDGET_FAIL';

export const SWAG_SELECT_UPDATE_RESTRICTED_PRODUCTS_REQUEST = 'SWAG_SELECT_UPDATE_RESTRICTED_PRODUCTS_REQUEST';
export const SWAG_SELECT_UPDATE_RESTRICTED_PRODUCTS_SUCCESS = 'SWAG_SELECT_UPDATE_RESTRICTED_PRODUCTS_SUCCESS';
export const SWAG_SELECT_UPDATE_RESTRICTED_PRODUCTS_FAIL = 'SWAG_SELECT_UPDATE_RESTRICTED_PRODUCTS_FAIL';

export const SWAG_SELECT_UPDATE_CAMPAIGN_LANDING_REQUEST = 'SWAG_SELECT_UPDATE_CAMPAIGN_LANDING_REQUEST';
export const SWAG_SELECT_UPDATE_CAMPAIGN_LANDING_SUCCESS = 'SWAG_SELECT_UPDATE_CAMPAIGN_LANDING_SUCCESS';
export const SWAG_SELECT_UPDATE_CAMPAIGN_LANDING_FAIL = 'SWAG_SELECT_UPDATE_CAMPAIGN_LANDING_FAIL';

export const SWAG_SELECT_UPDATE_RECIPIENT_ACTIONS_REQUEST = 'SWAG_SELECT_UPDATE_RECIPIENT_ACTIONS_REQUEST';
export const SWAG_SELECT_UPDATE_RECIPIENT_ACTIONS_SUCCESS = 'SWAG_SELECT_UPDATE_RECIPIENT_ACTIONS_SUCCESS';
export const SWAG_SELECT_UPDATE_RECIPIENT_ACTIONS_FAIL = 'SWAG_SELECT_UPDATE_RECIPIENT_ACTIONS_FAIL';

export const SWAG_SELECT_GENERATE_CODES_REQUEST = 'SWAG_SELECT_GENERATE_CODES_REQUEST';
export const SWAG_SELECT_GENERATE_CODES_SUCCESS = 'SWAG_SELECT_GENERATE_CODES_SUCCESS';
export const SWAG_SELECT_GENERATE_CODES_FAIL = 'SWAG_SELECT_GENERATE_CODES_FAIL';

export const SWAG_SELECT_GENERATION_CODES_PROGRESS_REQUEST = 'SWAG_SELECT_GENERATION_CODES_PROGRESS_REQUEST';
export const SWAG_SELECT_GENERATION_CODES_PROGRESS_SUCCESS = 'SWAG_SELECT_GENERATION_CODES_PROGRESS_SUCCESS';
export const SWAG_SELECT_GENERATION_CODES_PROGRESS_FAIL = 'SWAG_SELECT_GENERATION_CODES_PROGRESS_FAIL';
export const SWAG_SELECT_GENERATION_CODES_PROGRESS_FINISH = 'SWAG_SELECT_GENERATION_CODES_PROGRESS_FINISH';

export const SWAG_SELECT_SAVE_PHYSICAL_CARD_REQUEST = 'SWAG_SELECT_SAVE_PHYSICAL_CARD_REQUEST';
export const SWAG_SELECT_SAVE_PHYSICAL_CARD_SUCCESS = 'SWAG_SELECT_SAVE_PHYSICAL_CARD_SUCCESS';
export const SWAG_SELECT_SAVE_PHYSICAL_CARD_FAIL = 'SWAG_SELECT_SAVE_PHYSICAL_CARD_FAIL';

export const SWAG_SELECT_UPDATE_CARDS_ORDER_DATA_REQUEST = 'SWAG_SELECT_UPDATE_CARDS_ORDER_DATA_REQUEST';
export const SWAG_SELECT_UPDATE_CARDS_ORDER_DATA_SUCCESS = 'SWAG_SELECT_UPDATE_CARDS_ORDER_DATA_SUCCESS';
export const SWAG_SELECT_UPDATE_CARDS_ORDER_DATA_FAIL = 'SWAG_SELECT_UPDATE_CARDS_ORDER_DATA_FAIL';

export const SWAG_SELECT_SEND_ORDER_TO_PROCESSING_REQUEST = 'SWAG_SELECT_SEND_ORDER_TO_PROCESSING_REQUEST';
export const SWAG_SELECT_SEND_ORDER_TO_PROCESSING_SUCCESS = 'SWAG_SELECT_SEND_ORDER_TO_PROCESSING_SUCCESS';
export const SWAG_SELECT_SEND_ORDER_TO_PROCESSING_FAIL = 'SWAG_SELECT_SEND_ORDER_TO_PROCESSING_FAIL';
