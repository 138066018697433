import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { MessageOption } from '../store/prospectingCampaign/steps/messaging/messaging.types';

export const CreateNewMessageOption: MessageOption = {
  name: 'New Template',
  subject: '',
  message: '',
  templateId: 0,
};

export const MultipleTemplatesOption: MessageOption = {
  name: 'Assign multiple templates',
  subject: '',
  message: '',
  templateId: -1,
};

export const CustomMessageOption = [CreateNewMessageOption, MultipleTemplatesOption];

export const CustomMessageIcons = {
  [CreateNewMessageOption.templateId as number]: AddIcon,
  [MultipleTemplatesOption.templateId as number]: ModeEditIcon,
};

export const MIN_REQUIRED_ALLOWED_TEMPLATES = 2;

export const TEMPLATE_QUERY_PARAMS = {
  includeDeleted: true,
};
