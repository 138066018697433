import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { IDashboardAccessForm, Security, SettingsItem } from '@alycecom/modules';
import { CircularProgress, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AlyceTheme, Button, Icon, Tooltip } from '@alycecom/ui';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

const {
  DashboardRestrictionPolicies,
  EdAccessFormFields,
  updateRevenueImpactDashboardAccess,
  getRevenueImpactDashboardAccess,
  getIsSecuritySettingsLoading,
  getIsSecuritySettingsLoaded,
} = Security;

const useStyles = makeStyles<AlyceTheme>(() => ({
  root: {
    borderTop: 'none',
  },
  tooltip: {
    width: 165,
  },
  tooltipIcon: {
    fontSize: 12,
  },
}));

const RevenueImpactDashboardAccess = () => {
  const dispatch = useDispatch();
  const isDashboardAccessEnabled = useSelector(getRevenueImpactDashboardAccess);
  const isSecuritySettingsLoading = useSelector(getIsSecuritySettingsLoading);
  const isSecuritySettingsLoaded = useSelector(getIsSecuritySettingsLoaded);
  const savedPermissionPolicy = useMemo(
    () => (isDashboardAccessEnabled ? DashboardRestrictionPolicies.All : DashboardRestrictionPolicies.Admins),
    [isDashboardAccessEnabled],
  );
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<IDashboardAccessForm>({
    mode: 'onChange',
    defaultValues: {
      [EdAccessFormFields.RestrictionPolicy]: DashboardRestrictionPolicies.All,
    },
  });
  const submitHandler = useCallback(
    (formValues: IDashboardAccessForm) => {
      const currentDashboardAccess = formValues[EdAccessFormFields.RestrictionPolicy];
      const enabled = currentDashboardAccess === DashboardRestrictionPolicies.All;
      dispatch(updateRevenueImpactDashboardAccess({ enabled }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (isSecuritySettingsLoaded) {
      reset({
        [EdAccessFormFields.RestrictionPolicy]: savedPermissionPolicy,
      });
    }
  }, [reset, isSecuritySettingsLoaded, savedPermissionPolicy]);

  return (
    <SettingsItem
      title="Revenue Impact Dashboard Access"
      overrideClasses={{ root: classes.root }}
      description={
        <>
          <Typography>Manage which users are able to view the Revenue Impact Dashboard.</Typography>
          <Typography display="inline" component="span">
            Current access level is set to
          </Typography>
        </>
      }
      isLoading={false}
      value={
        savedPermissionPolicy === DashboardRestrictionPolicies.All ? 'All users' : 'Only team and organization admins'
      }
    >
      <form onSubmit={handleSubmit(submitHandler)}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Controller
              name={EdAccessFormFields.RestrictionPolicy}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <RadioGroup {...field} aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                  <FormControlLabel
                    value={DashboardRestrictionPolicies.Admins}
                    control={<Radio />}
                    label={
                      <Typography>Only team and company admin users can view the Revenue Impact Dashboard</Typography>
                    }
                  />
                  <FormControlLabel
                    value={DashboardRestrictionPolicies.All}
                    control={<Radio />}
                    label={
                      <Typography>
                        All users can view the Revenue Impact Dashboard{' '}
                        <Tooltip
                          component="span"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                          title="Non-admins will only see the data for teams they belong to"
                        >
                          <Icon display="inline" className={classes.tooltipIcon} color="link.main" icon="info-circle" />
                        </Tooltip>
                      </Typography>
                    }
                  />
                </RadioGroup>
              )}
            />
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={!isValid || isSecuritySettingsLoading}
              startIcon={isSecuritySettingsLoading && <CircularProgress color="inherit" size={20} />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </SettingsItem>
  );
};

export default memo(RevenueImpactDashboardAccess);
