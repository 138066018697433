import { StateStatus } from '@alycecom/utils';
import { omit } from 'ramda';
import { createReducer } from 'redux-act';

import { ICampaignListItem } from '../../../../campaigns/campaigns.types';

import {
  clearWorkatoActiveOneToOneCampaigns,
  loadWorkatoActiveOneToOneCampaigns,
} from './activeOneToOneCampaigns.actions';

export type TActiveOneToOneCampaignsState = {
  [campaignIdentifier: string]: { campaigns: ICampaignListItem[]; status: StateStatus };
};

const initialState: TActiveOneToOneCampaignsState = {};

export const activeOneToOneCampaigns = createReducer({}, initialState)
  .on(loadWorkatoActiveOneToOneCampaigns.pending, (state, { autocompleteIdentifier }) => ({
    ...state,
    [autocompleteIdentifier]: {
      campaigns: [],
      status: StateStatus.Pending,
    },
  }))
  .on(loadWorkatoActiveOneToOneCampaigns.fulfilled, (state, { autocompleteIdentifier, campaigns }) => ({
    ...state,
    [autocompleteIdentifier]: {
      campaigns,
      status: StateStatus.Fulfilled,
    },
  }))
  .on(loadWorkatoActiveOneToOneCampaigns.rejected, (state, { autocompleteIdentifier }) => ({
    ...state,
    [autocompleteIdentifier]: {
      campaigns: [],
      status: StateStatus.Rejected,
    },
  }))
  .on(clearWorkatoActiveOneToOneCampaigns, (state, { autocompleteIdentifier }) => ({
    ...omit([autocompleteIdentifier], state),
  }));
