import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Features } from '@alycecom/modules';

import influitiveIcon from '../../../../../../../assets/images/influitive.svg';
import { IConfigurableIntegration, Integrations, TIntegrationStatus } from '../models/IntegrationsModels';
import { IntegrationCard } from '../../IntegrationCard/IntegrationCard';
import {
  INTEGRATION_STATUS_ACTIVE,
  INTEGRATION_STATUS_LOCKED,
} from '../../../../../constants/organizationSettings.constants';
import { useOpenIntegration } from '../../../../../hooks/useOpenIntegration';
import { getApplications } from '../../../../../store/organisation/applications/organisationApplications.selectors';
import {
  getInfluitiveIsLoading,
  getIsInfluitiveWebhookAvailable,
} from '../../../../../store/organisation/integrations/influitive/influitive.selectors';
import { loadOrganizationInfluitiveIntegration } from '../../../../../store/organisation/integrations/influitive/influitive.actions';

interface IIntegratedApplication {
  type: string;
  status: TIntegrationStatus;
}

const description =
  'Alyce for Influitive allows the automatic fulfillment of gifts selected via the Influitive customer advocacy platform, which leverages gamification to drive loyalty by engaging customers in activities like referrals, reviews, and case studies.';
const InfluitiveIntegration = ({ url }: IConfigurableIntegration): JSX.Element => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getInfluitiveIsLoading) as boolean;
  const selectIntegration = useOpenIntegration(url);
  const isInfluitiveEnabled = useSelector(Features.selectors.hasFeatureFlag(Features.FLAGS.INFLUITIVE_INTEGRATION));
  const isWebhookAvailable = useSelector(getIsInfluitiveWebhookAvailable);
  const applications = useSelector(getApplications) as IIntegratedApplication[];

  const integrationStatus = useMemo(() => {
    if (!isInfluitiveEnabled) {
      return INTEGRATION_STATUS_LOCKED;
    }

    if (isWebhookAvailable) {
      return INTEGRATION_STATUS_ACTIVE;
    }

    const influitiveApplication = applications.find(app => app.type === Integrations.Influitive);

    return influitiveApplication?.status;
  }, [applications, isInfluitiveEnabled, isWebhookAvailable]);

  useEffect(() => {
    dispatch(loadOrganizationInfluitiveIntegration());
  }, [dispatch]);

  return (
    <IntegrationCard
      title="Influitive"
      isLoading={isLoading}
      logoSrc={influitiveIcon}
      description={description}
      status={integrationStatus}
      shouldGoToMarketplace={false}
      open={() => selectIntegration(Integrations.Influitive)}
    />
  );
};

export default memo(InfluitiveIntegration);
