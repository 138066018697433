import { Epic, ofType as classicOfType } from 'redux-observable';
import { ofType } from '@alycecom/utils';
import { filter, map, mapTo, withLatestFrom } from 'rxjs/operators';
import { parse } from 'query-string';

import { UPDATE_STATE_UPDATED_TIME } from '../../../../../store/common/stateUpdatedTime/stateUpdatedTime.types';

import { giftsBreakdownEpics } from './giftsBreakdown/giftsBreakdown.epics';
import { giftsFiltersEpics } from './filters/filters.epics';
import { setFilters } from './filters/filters.actions';
import { fetchGiftsRequest, resetSelection } from './giftsBreakdown/giftsBreakdown.actions';
import { getFiltersAsPayload } from './filters/filters.selectors';
import { fetchGiftsWithStoredFilters } from './gifts.actions';
import { IGiftsBreakdownListRequestPayload } from './giftsBreakdown/giftsBreakdown.types';

const fetchGiftsWithStoredFiltersEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(fetchGiftsWithStoredFilters),
    withLatestFrom(state$),
    map(([, state]) => fetchGiftsRequest(getFiltersAsPayload(state) as IGiftsBreakdownListRequestPayload)),
  );

const reloadGiftsWhenFiltersChangedEpic: Epic = (action$, state$) =>
  action$.pipe(ofType(setFilters), withLatestFrom(state$), mapTo(fetchGiftsWithStoredFilters()));

const resetSelectionWhenStatusFilterChangedEpic: Epic = action$ =>
  action$.pipe(
    ofType(setFilters),
    filter(({ payload }) => {
      const currentParams = parse(window.location.search);
      return 'giftsState' in payload && payload.giftsState !== currentParams.giftsState;
    }),
    mapTo(resetSelection()),
  );

export const dispatchFiltersToRequestByGiftFlowEpic: Epic = action$ =>
  action$.pipe(classicOfType(UPDATE_STATE_UPDATED_TIME), mapTo(fetchGiftsWithStoredFilters()));

export const giftsEpics = [
  ...giftsBreakdownEpics,
  ...giftsFiltersEpics,
  fetchGiftsWithStoredFiltersEpic,
  reloadGiftsWhenFiltersChangedEpic,
  resetSelectionWhenStatusFilterChangedEpic,
  dispatchFiltersToRequestByGiftFlowEpic,
];
