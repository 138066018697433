import { createAsyncAction } from '@alycecom/utils';

import {
  IInfluitiveCreateCampaignErrors,
  IInfluitiveCreateCampaignPayload,
  InfluitiveCampaignResponse,
} from '../types/Influitive.types';

export const PREFIX = 'INFLUITIVE';

export const createInfluitiveCampaignAction = createAsyncAction<
  IInfluitiveCreateCampaignPayload,
  InfluitiveCampaignResponse,
  IInfluitiveCreateCampaignErrors
>(`${PREFIX}_CREATE_CAMPAIGN`);
