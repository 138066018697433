import { createReducer } from 'redux-act';
import { StateStatus } from '@alycecom/utils';

import {
  clearOrganizationInfluitiveWebhook,
  loadOrganizationInfluitiveIntegration,
  updateOrganizationInfluitiveIntegration,
} from './influitive.actions';
import { IInfluitiveWebhook, InfluitiveWebhookErrors } from './influitive.types';

export interface IInfluitiveState {
  state: StateStatus;
  webhook: IInfluitiveWebhook;
  isConnectionAvailable: boolean;
  errors: InfluitiveWebhookErrors;
}
const initialState: IInfluitiveState = {
  state: StateStatus.Idle,
  errors: {},
  isConnectionAvailable: false,
  webhook: {
    webhookUrl: '',
  },
};

export const influitive = createReducer({}, initialState)
  .on(loadOrganizationInfluitiveIntegration.pending, state => ({
    ...state,
    state: StateStatus.Pending,
  }))
  .on(loadOrganizationInfluitiveIntegration.fulfilled, (state, webhook) => ({
    ...state,
    webhook,
    state: StateStatus.Fulfilled,
    isConnectionAvailable: !!webhook.webhookUrl,
  }))
  .on(loadOrganizationInfluitiveIntegration.rejected, state => ({
    ...state,
    state: StateStatus.Rejected,
    isConnectionAvailable: false,
  }))
  .on(updateOrganizationInfluitiveIntegration.pending, state => ({
    ...state,
    state: StateStatus.Pending,
  }))
  .on(updateOrganizationInfluitiveIntegration.fulfilled, (state, webhook) => ({
    ...state,
    webhook,
    state: StateStatus.Fulfilled,
    isConnectionAvailable: !!webhook.webhookUrl,
  }))
  .on(updateOrganizationInfluitiveIntegration.rejected, (state, payload) => ({
    ...state,
    state: StateStatus.Rejected,
    errors: payload,
  }))
  .on(clearOrganizationInfluitiveWebhook, state => ({
    ...state,
    webhook: { ...initialState.webhook },
  }));
