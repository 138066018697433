import React, { useState, useCallback, ReactElement, ReactNode, memo } from 'react';
import classNames from 'classnames';
import { Box, Typography, BoxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  ProductImagesGallery,
  AlyceTheme,
  DescriptionTab,
  NumberFormat,
  IProduct,
  IProductDetails,
  Button,
  IProductPrice,
} from '@alycecom/ui';
import { IProductImagesGalleryOverrideClasses } from '@alycecom/ui/dist/components/ProductDetails/ProductImagesGallery/ProductImagesGallery';
import {
  IGiftCardRange,
  IProductBrand,
} from '@alycecom/ui/dist/components/Marketplace/MarketplaceProduct/MarketplaceProduct';
import { useSelector } from 'react-redux';
import { Features } from '@alycecom/modules';

import ProductPriceDropdown from '../../EmbeddedMarketplace/ProductPriceDropdown/ProductPriceDropdown';
import ProductPriceInput from '../../EmbeddedMarketplace/ProductPriceInput/ProductPriceInput';

export enum ProductTabs {
  description = 'Description',
  details = 'Details',
}

export interface IProductProps {
  product?: IProduct;
  productDetails?: IProductDetails & { giftCardRange?: IGiftCardRange; hasRangeDenomination?: boolean };
  onShowMerchant: () => void;
  onChoose: (product: {
    image: string;
    hasOptions: boolean;
    countryId: number;
    hasExternalFulfillment: boolean;
    denomination: { price: number };
    giftCardRange?: IGiftCardRange;
    isTangoCard: boolean;
    // eslint-disable-next-line camelcase
    denomination_currency_id: number;
    provider: string;
    name: string;
    options: string;
    hasRangeDenomination: boolean;
    typeId: number;
    id: number;
    brand: IProductBrand;
    isAddressRequired: boolean;
    localPrice?: IProductPrice;
    isDonation: boolean;
  }) => void;
  onToggleTab?: (tab: ProductTabs) => void;
  children?: ReactNode;
  minPrice: number;
  wrapperProps?: BoxProps;
  overrideClasses?: IProductImagesGalleryOverrideClasses & {
    merchantLink?: string;
  };
}

const useStyles = makeStyles<AlyceTheme>(({ palette }) => ({
  title: {
    fontSize: 24,
    lineHeight: 1.33,
    color: palette.text.primary,
  },
  subTitle: {
    fontSize: 16,
    lineHeight: 1.5,
    color: palette.grey.main,
  },
  merchantLink: {
    color: palette.link.main,
    cursor: 'pointer',
  },
}));

const Product = ({
  product,
  productDetails,
  children,
  onShowMerchant,
  minPrice,
  onToggleTab,
  wrapperProps = {},
  overrideClasses = {},
  onChoose = () => {},
}: IProductProps): ReactElement => {
  const classes = useStyles();
  const [openTab, setOpenTab] = useState<ProductTabs | boolean>(ProductTabs.description || false);
  const [isWrongDenomination, setIsWrongDenomination] = useState<boolean>(false);
  const [denomination, setDenomination] = useState<number>(product?.giftCardRange?.minPrice || minPrice);
  const isMultipleGiftCardValuesEnabled = useSelector(
    Features.selectors.hasFeatureFlag(Features.FLAGS.MULTIPLE_GIFT_CARD_VALUES),
  );
  const minValue = productDetails?.giftCardRange?.minPrice || 0;
  const maxValue = productDetails?.giftCardRange?.maxPrice || 0;

  const handleToggleTab = useCallback(
    (tab: ProductTabs) => {
      const newOpenTab = tab === openTab ? false : tab;
      setOpenTab(newOpenTab);
      if (!newOpenTab) {
        // eslint-disable-next-line no-unused-expressions
        onToggleTab && onToggleTab(tab);
      }
    },
    [openTab, onToggleTab],
  );

  const getTabIcon = useCallback((tab: ProductTabs) => (tab === openTab ? 'angle-up' : 'angle-down'), [openTab]);

  const handleChangeDenomination = useCallback(
    (value: number) => {
      setDenomination(value);
      const isCorrect = value >= minValue && value <= maxValue && value % 5 === 0;
      setIsWrongDenomination(!isCorrect);
    },
    [minValue, maxValue],
  );

  const handleChooseProduct = useCallback(() => {
    if (isMultipleGiftCardValuesEnabled && product?.hasRangeDenomination) {
      onChoose({
        ...product,
        denomination: {
          ...product.denomination,
          price: denomination,
        },
      });
    } else {
      // @ts-ignore
      onChoose(product);
    }
  }, [isMultipleGiftCardValuesEnabled, product, denomination, onChoose]);

  const isChooseProductDisabled =
    isMultipleGiftCardValuesEnabled && productDetails?.hasRangeDenomination && isWrongDenomination;

  return (
    <>
      <Box
        className={classNames('productDetailsWrapper-ie', {
          'productDetailsWrapperWithOptions-ie': !!productDetails?.options,
        })}
        width={1}
        px={3}
        py={0}
        {...wrapperProps}
      >
        <Box width={1} mt={2} mb={1} textAlign="left">
          <Typography className={classes.title}>
            {!isMultipleGiftCardValuesEnabled && productDetails?.localPrice && (
              <>
                {productDetails.localPrice.currencySign}
                <NumberFormat format="0,0">{productDetails.localPrice.price}</NumberFormat>
              </>
            )}
            {productDetails?.name}
          </Typography>
          {!productDetails?.hasExternalFulfillment && (
            <Typography className={classes.subTitle}>
              from{' '}
              <span
                role="link"
                tabIndex={0}
                onClick={onShowMerchant}
                onKeyDown={onShowMerchant}
                className={classNames(classes.merchantLink, overrideClasses.merchantLink)}
              >
                {productDetails?.provider.name}
              </span>
            </Typography>
          )}
        </Box>
        <ProductImagesGallery
          images={productDetails?.images ?? []}
          isTangoCard={productDetails?.isTangoCard}
          overrideClasses={overrideClasses}
        />
        {isMultipleGiftCardValuesEnabled && productDetails?.hasRangeDenomination && (
          <Box pt={2} pb={2}>
            {productDetails?.giftCardRange?.denominations &&
            productDetails?.giftCardRange?.denominations?.length > 0 ? (
              <ProductPriceDropdown onChange={handleChangeDenomination} product={productDetails} />
            ) : (
              <ProductPriceInput onChange={handleChangeDenomination} product={productDetails} />
            )}
          </Box>
        )}
        <Box pt={2} pb={2} display="flex" flexDirection="row" justifyContent="flex-end">
          <Button
            fullWidth
            variant="contained"
            disabled={isChooseProductDisabled}
            color="secondary"
            onClick={handleChooseProduct}
          >
            <span>Choose Product</span>
          </Button>
        </Box>
        <DescriptionTab
          title={ProductTabs.description}
          isOpen={openTab === ProductTabs.description}
          toggleOpen={() => handleToggleTab(ProductTabs.description)}
          content={productDetails?.description ?? ''}
        />
        {productDetails?.specialDescription && (
          <DescriptionTab
            title={ProductTabs.details}
            icon={getTabIcon(ProductTabs.details)}
            isOpen={openTab === ProductTabs.details}
            toggleOpen={() => handleToggleTab(ProductTabs.details)}
            content={productDetails?.specialDescription}
          />
        )}
      </Box>
      {children}
    </>
  );
};

export default memo(Product);
