import { useCallback, useMemo } from 'react';
import { useRouting } from '@alycecom/hooks';

import { InfluitivePages } from '../constants/influitive.constants';

interface UseInfluitiveNavigationHook {
  goToCampaignCreate: () => void;
}

export function useInfluitiveNavigation(): UseInfluitiveNavigationHook {
  const go = useRouting();
  const goToCampaignCreate = useCallback(() => {
    go(InfluitivePages.CREATE_CAMPAIGN);
  }, [go]);

  return useMemo(() => ({ goToCampaignCreate }), [goToCampaignCreate]);
}
