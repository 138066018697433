import { Epic } from 'redux-observable';
import { ofType } from '@alycecom/utils';
import { Auth } from '@alycecom/modules';
import { mapTo } from 'rxjs/operators';

import { resetRoiFilters } from './filters.actions';

const clearFiltersOnLogout: Epic = action$ =>
  action$.pipe(ofType(Auth.actions.logoutRequest), mapTo(resetRoiFilters()));

export const filtersEpics = [clearFiltersOnLogout];
