import React, { memo, MouseEvent, useCallback, useEffect, useState } from 'react';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import Close from '@mui/icons-material/Close';
import { useApplyManualFormErrorsEffect } from '@alycecom/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from '@alycecom/ui';

import { IInfluitiveCredentials } from '../../../../../../store/organisation/integrations/influitive/influitive.types';
import {
  getInfluitiveErrors,
  getInfluitiveIsLoading,
  getInfluitiveWebhook,
  getIsInfluitiveWebhookAvailable,
} from '../../../../../../store/organisation/integrations/influitive/influitive.selectors';
import {
  clearOrganizationInfluitiveWebhook,
  loadOrganizationInfluitiveIntegration,
  updateOrganizationInfluitiveIntegration,
} from '../../../../../../store/organisation/integrations/influitive/influitive.actions';
import {
  INFLUITIVE_HELP_PAGE,
  INFLUITIVE_WILDCARD,
} from '../../../../../../store/organisation/integrations/influitive/influitive.constants';
import {
  connectAlyceAndInfluitiveFormDefaults,
  connectAlyceAndInfluitiveFormResolver,
} from '../../../../../../store/organisation/integrations/influitive/influitive.schemas';

import StepToConnect from './StepToConnect';

const useStyles = makeStyles(({ spacing, palette }) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 456,
    gap: spacing(3),
  },
  endAdornment: {
    marginRight: spacing(-1.5),
  },
  closeIcon: {
    fontSize: '1rem',
    color: palette.primary.main,
  },
  updateConnectionButton: {
    minWidth: 204,
  },
}));

const ConnectAlyceAndInfluitive = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { control, setError, setValue, handleSubmit, getValues, formState } = useForm<IInfluitiveCredentials>({
    mode: 'all',
    defaultValues: { ...connectAlyceAndInfluitiveFormDefaults },
    resolver: connectAlyceAndInfluitiveFormResolver,
  });
  const errors = useSelector(getInfluitiveErrors);
  const webhook = useSelector(getInfluitiveWebhook);
  const isWebhookAvailable = useSelector(getIsInfluitiveWebhookAvailable);
  const isLoading = useSelector(getInfluitiveIsLoading);
  const [isFormFieldsDisabled, setIsFormFieldsDisabled] = useState(false);
  const isEdit = !webhook.webhookUrl && isWebhookAvailable;
  const isFormDisabled = isFormFieldsDisabled || isLoading;
  useApplyManualFormErrorsEffect<IInfluitiveCredentials>(setError, errors);

  const submit = useCallback(
    (form: IInfluitiveCredentials) => {
      dispatch(updateOrganizationInfluitiveIntegration(form));
    },
    [dispatch],
  );
  const handleUpdateConnection = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (isEdit) {
        const form = getValues();
        dispatch(updateOrganizationInfluitiveIntegration(form));
      } else {
        dispatch(clearOrganizationInfluitiveWebhook());
      }
    },
    [dispatch, getValues, isEdit],
  );
  const handleCancelUpdate = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      dispatch(loadOrganizationInfluitiveIntegration());
    },
    [dispatch],
  );

  const clearField = useCallback(
    (fieldName: keyof IInfluitiveCredentials) => setValue(fieldName, '', { shouldValidate: true }),
    [setValue],
  );

  useEffect(() => {
    let formDisabled = false;
    let initialValues = '';

    if (webhook.webhookUrl) {
      formDisabled = true;
      initialValues = INFLUITIVE_WILDCARD;
    }

    setIsFormFieldsDisabled(formDisabled);
    setValue('influitiveAuthorizationToken', initialValues);
    setValue('influitiveXOrgId', initialValues);
  }, [setValue, webhook]);

  return (
    <StepToConnect
      id="ConnectAlyceAndInfluitive"
      title="1. Connect Alyce and Influitive"
      description={
        <>
          Please follow these steps to connect your Alyce account to the Influitive integration. If you need any help,
          feel free to reach out to us or{' '}
          <a href={INFLUITIVE_HELP_PAGE} target="_blank" rel="noopener noreferrer">
            view our help docs
          </a>
        </>
      }
    >
      <form className={classes.form} onSubmit={handleSubmit(submit)}>
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label="Influitive authorization token"
              variant="outlined"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              fullWidth
              disabled={isFormDisabled}
              InputProps={{
                inputProps: {
                  'data-testid': 'ConnectAlyceAndInfluitive.AuthorizationTokenField',
                },
                endAdornment: !!field.value && !isFormDisabled && (
                  <InputAdornment position="end">
                    <IconButton className={classes.endAdornment} onClick={() => clearField(field.name)} size="large">
                      <Close className={classes.closeIcon} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          name="influitiveAuthorizationToken"
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label="X_ORG_ID"
              variant="outlined"
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              fullWidth
              disabled={isFormDisabled}
              InputProps={{
                inputProps: {
                  'data-testid': 'ConnectAlyceAndInfluitive.XOrgIdField',
                },
                endAdornment: !!field.value && !isFormDisabled && (
                  <InputAdornment position="end">
                    <IconButton className={classes.endAdornment} onClick={() => clearField(field.name)} size="large">
                      <Close className={classes.closeIcon} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          name="influitiveXOrgId"
        />
        <Box>
          {isWebhookAvailable ? (
            <Button
              variant="contained"
              color="secondary"
              type="button"
              disabled={isLoading || (isEdit && !formState.isValid)}
              onClick={handleUpdateConnection}
              data-testid="ConnectAlyceAndInfluitive.UpdateConnectionButton"
              startIcon={isLoading && <Icon spin color="inherit" icon="spinner" />}
              className={classes.updateConnectionButton}
            >
              Update connection
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isLoading || !formState.isValid}
              data-testid="ConnectAlyceAndInfluitive.ConnectButton"
              startIcon={isLoading && <Icon spin color="inherit" icon="spinner" />}
            >
              Connect
            </Button>
          )}
          {isEdit && (
            <Button
              variant="contained"
              color="red"
              type="button"
              disabled={isLoading}
              onClick={handleCancelUpdate}
              data-testid="ConnectAlyceAndInfluitive.CancelUpdateButton"
              startIcon={isLoading && <Icon spin color="inherit" icon="spinner" />}
            >
              Cancel
            </Button>
          )}
        </Box>
      </form>
    </StepToConnect>
  );
};

export default memo(ConnectAlyceAndInfluitive);
