import { array, boolean, number, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BudgetCreateField,
  BudgetType,
  PauseGiftingOnOption,
  TBudgetCreateParams,
  FiscalYear,
  DeductFrom,
} from '@alycecom/services';

import { MAX_BUDGET } from '../../../constants/teamSidebarProgress.constants';
import { RefreshPeriod } from '../budgets/budgets.types';

const idSchema = number();
const amountSchema = number();

export const teamBudgetSchema = object().shape({
  [BudgetCreateField.Amount]: number()
    .required()
    .default(0)
    .label('Team budget')
    .positive()
    .min(0)
    .max(MAX_BUDGET, "The Team's budget must not exceed $99,999,999.99"),
  [BudgetCreateField.Type]: string().required().default(BudgetType.User).label('Budget type'),
  [BudgetCreateField.RefreshPeriod]: string().required().default(RefreshPeriod.Monthly).label('Refresh Period'),
  [BudgetCreateField.FiscalYear]: number()
    .required()
    .default(null)
    .when(BudgetCreateField.RefreshPeriod, {
      is: (refreshPeriod: RefreshPeriod) =>
        refreshPeriod === RefreshPeriod.Quarterly || refreshPeriod === RefreshPeriod.Annually,
      then: schema =>
        schema
          .min(FiscalYear.January, 'To continue, please select budget reset month')
          .max(FiscalYear.December, 'To continue, please select budget reset month'),
    })
    .label('Fiscal Year Start'),
  [BudgetCreateField.PauseOption]: string()
    .required()
    .default(PauseGiftingOnOption.Claimed)
    .label('Pause gifting when'),
  [BudgetCreateField.DeductFrom]: string().required().default(DeductFrom.SENDER).label('Deduct Send As From'),
  [BudgetCreateField.TeamMemberBudgets]: array()
    .of(
      object().shape({
        userId: idSchema,
        amount: amountSchema,
      }),
    )
    .default([]),
  [BudgetCreateField.NotifyTeamAdminType]: string().nullable().default(null),
  [BudgetCreateField.NotifySenderType]: string().nullable().default(null),
  [BudgetCreateField.NotifySenderAtPercent]: number().nullable().default(null),
  [BudgetCreateField.NotifyTeamAdminAtPercent]: number().nullable().default(null),
  [BudgetCreateField.Rollover]: boolean().default(false),
});

export const teamBudgetFormDefaultValues = teamBudgetSchema.cast({
  [BudgetCreateField.Amount]: 0,
  [BudgetCreateField.Type]: BudgetType.User,
  [BudgetCreateField.RefreshPeriod]: RefreshPeriod.Monthly,
  [BudgetCreateField.FiscalYear]: 0,
  [BudgetCreateField.DeductFrom]: DeductFrom.SENDER,
  [BudgetCreateField.RefreshPeriod]: RefreshPeriod.Monthly,
  [BudgetCreateField.PauseOption]: PauseGiftingOnOption.Claimed,
  [BudgetCreateField.TeamMemberBudgets]: [],
}) as TBudgetCreateParams;

export const teamBudgetFormResolver = yupResolver(teamBudgetSchema);
