import { createReducer } from 'redux-act';

import { showNotification, showIntegrationsAlert } from './notifications.actions';

export interface TShowNotification {
  status: boolean | null;
  isIntegrationAlertShowed: boolean;
}

export const initialState: TShowNotification = {
  status: false,
  isIntegrationAlertShowed: false,
};

export default createReducer({}, initialState)
  .on(showNotification, (state, payload) => ({
    ...state,
    status: payload,
  }))
  .on(showIntegrationsAlert, state => ({
    ...state,
    isIntegrationAlertShowed: true,
  }));
