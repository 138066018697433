import { Features } from '@alycecom/modules';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Integrations,
  TIntegrationStatus,
} from '../components/OrganisationSettingsModule/Integrations/InHouseIntegrations/models/IntegrationsModels';
import {
  INTEGRATION_STATUS_ACTIVE,
  INTEGRATION_STATUS_ATTENTION,
  INTEGRATION_STATUS_LOCKED,
} from '../constants/organizationSettings.constants';
import { organisationApplicationsRequest } from '../store/organisation/applications/organisationApplications.actions';
import { getApplications } from '../store/organisation/applications/organisationApplications.selectors';
import {
  getOAuthIsLoading,
  getOAuthState,
  getOauthErrorMessage,
} from '../store/organisation/integrations/salesforce/sfOAuth.selectors';
import { ConnectionState } from '../store/organisation/integrations/salesforce/sfOAuth.types';
import { loadOAuthState } from '../store/organisation/integrations/salesforce/sfOauth.actions';

interface IIntegratedApplication {
  type: string;
  status: TIntegrationStatus;
}

interface IUseSalesforceIntegration {
  isLoading: boolean;
  error: string | null;
  integrationStatus: TIntegrationStatus | null;
}

interface IUseSalesforceIntegrationProps {
  fetchStateOnly?: boolean;
}

export const useSalesforceIntegration = (props?: IUseSalesforceIntegrationProps): IUseSalesforceIntegration => {
  const dispatch = useDispatch();
  const oAuthState = useSelector(getOAuthState);
  const oAuthError = useSelector(getOauthErrorMessage);
  const isLoading = useSelector(getOAuthIsLoading);
  const applications = useSelector(getApplications) as IIntegratedApplication[];
  const salesforceAvailable = useSelector(
    useMemo(() => Features.selectors.hasFeatureFlag(Features.FLAGS.SALES_FORCE_APP_ACCESS), []),
  );
  const salesforceApiIntegrationAvailable = useSelector(
    useMemo(() => Features.selectors.hasFeatureFlag(Features.FLAGS.SF_API_INTEGRATION), []),
  );

  useEffect(() => {
    if (salesforceAvailable) {
      // some users don't have access to salesforce applications
      if (!props?.fetchStateOnly) {
        dispatch(organisationApplicationsRequest());
      }
      dispatch(loadOAuthState());
    }
  }, [salesforceAvailable, dispatch, props?.fetchStateOnly]);

  const getSalesforceIntegrationStatus = useCallback(() => {
    if (!salesforceAvailable) {
      return INTEGRATION_STATUS_LOCKED;
    }

    const sfApplication = applications.find(app => app.type === Integrations.Salesforce);
    if (sfApplication === undefined) {
      return null;
    }

    if (
      sfApplication.status === INTEGRATION_STATUS_ACTIVE &&
      salesforceApiIntegrationAvailable &&
      oAuthState !== ConnectionState.Connected
    ) {
      return INTEGRATION_STATUS_ATTENTION;
    }

    return sfApplication.status;
  }, [salesforceApiIntegrationAvailable, oAuthState, applications, salesforceAvailable]);

  return useMemo(
    () => ({
      isLoading,
      error: oAuthError,
      integrationStatus: getSalesforceIntegrationStatus(),
    }),
    [isLoading, oAuthError, getSalesforceIntegrationStatus],
  );
};
