import { Epic } from 'redux-observable';
import { ofType } from '@alycecom/utils';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { push } from 'connected-react-router';

import { InfluitiveCreateCampaignResponse } from '../types/Influitive.types';
import { InfluitivePages } from '../constants/influitive.constants';

import { createInfluitiveCampaignAction } from './influitive.actions';

export const createInfluitiveCampaignEpic: Epic = (
  action$,
  state$,
  { apiService, messagesService: { errorHandler, showGlobalMessage, ERRORS } },
) =>
  action$.pipe(
    ofType(createInfluitiveCampaignAction.pending),
    switchMap(({ payload }) =>
      apiService.post(`/api/v1/campaigns/influitive/campaign`, { body: payload }, true).pipe(
        mergeMap((response: InfluitiveCreateCampaignResponse) => {
          if (response.success) {
            return [
              createInfluitiveCampaignAction.fulfilled(response.campaign),
              showGlobalMessage({ type: 'success', text: `Campaign ${response?.campaign?.name} has been created` }),
              push(InfluitivePages.INFLUITIVE_SETTINGS),
            ];
          }

          return [
            createInfluitiveCampaignAction.rejected(response.errors),
            showGlobalMessage({ type: 'error', text: ERRORS.SOMETHING_WRONG }),
          ];
        }),
        catchError(
          catchError(
            errorHandler({ callbacks: [createInfluitiveCampaignAction.rejected], message: ERRORS.SOMETHING_WRONG }),
          ),
        ),
      ),
    ),
  );

export const influitiveEpics = [createInfluitiveCampaignEpic];
