import { Epic, ofType as classicOfType } from 'redux-observable';
import { ofType, pickNotEmptyAndNil } from '@alycecom/utils';
import { catchError, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { applySpec } from 'ramda';
import { parse, stringify } from 'query-string';
import { LOCATION_CHANGE, replace } from 'connected-react-router';
import { match, matchPath } from 'react-router-dom';
import { handleError, handlers, IResponse } from '@alycecom/services';

import { fetchGiftsSuccess } from '../giftsBreakdown/giftsBreakdown.actions';

import {
  getCurrentPageFilter,
  getDateFromFilter,
  getDateToFilter,
  getLimitFilter,
  getSearchFilter,
  getSortDirectionFilter,
  getSortFieldFilter,
  getStatusFilter,
  getTeamIdsFilter,
  getCampaignIdsFilter,
  getSentByIdsFilter,
  getExpireDaysFilter,
  getRecipientSearchFilter,
  getSentAsIdsFilter,
  getCountryIdsFilter,
} from './filters.selectors';
import { fetchCampaignsFilterData, fetchSentByFilterData, fetchTeamsFilterData, setFilters } from './filters.actions';
import { IFilterItem, IGiftsFilters } from './filters.types';
import { initialGiftsFiltersState } from './filters.reducer';

export const GIFTS_ROUTES = {
  GIFTS_PATH: '/gifts',
  matchAnyGiftsPath(path: string): match<{}> | null {
    return matchPath(path, { path: this.GIFTS_PATH, exact: false });
  },
};

export const initGiftsFilterEpic: Epic = (action$, state$) =>
  action$.pipe(classicOfType(LOCATION_CHANGE), take(1)).pipe(
    filter(() => GIFTS_ROUTES.matchAnyGiftsPath(window.location.pathname) !== null),
    withLatestFrom(state$),
    map(() => {
      const {
        giftsState,
        search,
        teamIds,
        dateFrom,
        dateTo,
        sortField,
        sortDirection,
        currentPage,
        limit,
        campaignIds,
        sentByIds,
        expireDays,
        recipientSearch,
        sentAsIds,
        countryIds,
      } = parse(window.location.search, {
        arrayFormat: 'comma',
        parseNumbers: true,
      }) as Partial<IGiftsFilters>;

      const prepareArray = (ids?: number[] | null) => {
        if (!ids) {
          return [];
        }
        if (typeof ids === 'object') {
          return ids;
        }
        return [ids];
      };

      return setFilters({
        giftsState: giftsState ?? null,
        search: search ?? null,
        teamIds: prepareArray(teamIds),
        sentByIds: prepareArray(sentByIds),
        campaignIds: prepareArray(campaignIds),
        dateFrom: dateFrom ?? null,
        dateTo: dateTo ?? null,
        sortField: sortField || initialGiftsFiltersState.sortField,
        sortDirection: sortField ? sortDirection : initialGiftsFiltersState.sortDirection,
        currentPage: Number.isInteger(currentPage) ? currentPage : initialGiftsFiltersState.currentPage,
        limit: Number.isInteger(limit) ? limit : initialGiftsFiltersState.limit,
        expireDays: expireDays ?? null,
        recipientSearch: recipientSearch ?? null,
        sentAsIds: prepareArray(sentAsIds),
        countryIds: prepareArray(countryIds),
      });
    }),
  );

export const syncGiftsFilterToUrlQueryEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(fetchGiftsSuccess),
    filter(() => GIFTS_ROUTES.matchAnyGiftsPath(window.location.pathname) !== null),
    withLatestFrom(state$),
    map(([, state]) => {
      const {
        giftsState,
        search,
        teamIds,
        dateFrom,
        dateTo,
        sortField,
        sortDirection,
        currentPage,
        limit,
        campaignIds,
        sentByIds,
        expireDays,
        recipientSearch,
        sentAsIds,
        countryIds,
      } = applySpec({
        giftsState: getStatusFilter,
        search: getSearchFilter,
        teamIds: getTeamIdsFilter,
        campaignIds: getCampaignIdsFilter,
        dateFrom: getDateFromFilter,
        dateTo: getDateToFilter,
        sortField: getSortFieldFilter,
        sortDirection: getSortDirectionFilter,
        currentPage: getCurrentPageFilter,
        limit: getLimitFilter,
        sentByIds: getSentByIdsFilter,
        expireDays: getExpireDaysFilter,
        recipientSearch: getRecipientSearchFilter,
        sentAsIds: getSentAsIdsFilter,
        countryIds: getCountryIdsFilter,
      })(state);
      const currentParams = parse(window.location.search);
      const queryString = stringify(
        pickNotEmptyAndNil({
          ...currentParams,
          giftsState,
          search,
          teamIds,
          campaignIds,
          sentByIds,
          dateFrom,
          dateTo,
          sortField,
          sortDirection,
          currentPage,
          limit,
          expireDays,
          recipientSearch,
          sentAsIds,
          countryIds,
        }),
        { arrayFormat: 'comma' },
      );
      return replace({
        search: queryString,
      });
    }),
  );

export const loadCampaignFilterDataEpic: Epic = (action$, state$, { apiService }) =>
  action$.pipe(
    ofType(fetchCampaignsFilterData.pending),
    switchMap(() =>
      apiService.get('/api/v1/campaigns/list', {}, true).pipe(
        map(({ data }: IResponse<IFilterItem[]>) => fetchCampaignsFilterData.fulfilled(data)),
        catchError(handleError(handlers.handleAnyError(fetchCampaignsFilterData.rejected()))),
      ),
    ),
  );
export const loadTeamFilterDataEpic: Epic = (action$, state$, { apiService }) =>
  action$.pipe(
    ofType(fetchTeamsFilterData.pending),
    switchMap(() =>
      apiService.get('/api/v1/teams/list', {}, true).pipe(
        map(({ data }: IResponse<IFilterItem[]>) => fetchTeamsFilterData.fulfilled(data)),
        catchError(handleError(handlers.handleAnyError(fetchTeamsFilterData.rejected()))),
      ),
    ),
  );

export const loadSentByFilterDataEpic: Epic = (action$, state$, { apiService }) =>
  action$.pipe(
    ofType(fetchSentByFilterData.pending),
    switchMap(() =>
      apiService.get('/api/v1/teams/teammates', {}, true).pipe(
        // @ts-ignore
        map(({ data }: IResponse<{ id: number; firstName: string; lastName: string }[]>) => {
          const result: IFilterItem[] = data.map(item => ({
            id: item.id,
            name: `${item.firstName} ${item.lastName}`,
          }));
          return fetchSentByFilterData.fulfilled(result);
        }),
        catchError(handleError(handlers.handleAnyError(fetchSentByFilterData.rejected()))),
      ),
    ),
  );

export const giftsFiltersEpics = [
  initGiftsFilterEpic,
  syncGiftsFilterToUrlQueryEpic,
  loadCampaignFilterDataEpic,
  loadSentByFilterDataEpic,
  loadTeamFilterDataEpic,
];
