export const LOAD_TEMPLATES_REQUEST = 'LOAD_TEMPLATES_REQUEST';
export const LOAD_TEMPLATES_SUCCESS = 'LOAD_TEMPLATES_SUCCESS';
export const LOAD_TEMPLATES_FAIL = 'LOAD_TEMPLATES_FAIL';

export const SAVE_TEMPLATE_REQUEST = 'SAVE_TEMPLATE_REQUEST';
export const SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS';
export const SAVE_TEMPLATE_FAIL = 'SAVE_TEMPLATE_FAIL';

export const REMOVE_TEMPLATE_REQUEST = 'REMOVE_TEMPLATE_REQUEST';
export const REMOVE_TEMPLATE_SUCCESS = 'REMOVE_TEMPLATE_SUCCESS';
export const REMOVE_TEMPLATE_FAIL = 'REMOVE_TEMPLATE_FAIL';

export const ADD_NEW_TEMPLATE = 'ADD_NEW_TEMPLATE';
export const DISCARD_TEMPLATE_ADDING = 'DISCARD_TEMPLATE_ADDING';
export const RESET_TEMPLATE_ERRORS = 'RESET_TEMPLATE_ERRORS';
