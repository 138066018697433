import { createReducer } from 'redux-act';

import {
  fetchCampaignsFilterData,
  fetchSentByFilterData,
  fetchTeamsFilterData,
  resetFilters,
  setFilters,
  setSidebarIsOpen,
} from './filters.actions';
import { IGiftsFilters } from './filters.types';

export const initialGiftsFiltersState: IGiftsFilters = {
  isSidebarOpened: false,
  filtersData: {
    campaigns: {
      isLoading: false,
      data: [],
    },
    sentBy: {
      isLoading: false,
      data: [],
    },
    teams: {
      isLoading: false,
      data: [],
    },
  },
  countryIds: [],
  giftsState: null,
  search: null,
  teamIds: [],
  campaignIds: [],
  sentByIds: [],
  sortField: null,
  sortDirection: null,
  currentPage: 1,
  limit: null,
  dateFrom: null,
  dateTo: null,
  expireDays: null,
  recipientSearch: null,
  sentAsIds: [],
};

export const giftsFilters = createReducer<IGiftsFilters>({}, initialGiftsFiltersState);

giftsFilters
  .on(setFilters, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .on(resetFilters, state => ({
    ...initialGiftsFiltersState,
    filtersData: state.filtersData,
  }));

giftsFilters
  .on(fetchSentByFilterData.pending, state => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      sentBy: {
        isLoading: true,
        data: [],
      },
    },
  }))
  .on(fetchSentByFilterData.fulfilled, (state, payload) => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      sentBy: {
        isLoading: false,
        data: payload,
      },
    },
  }))
  .on(fetchSentByFilterData.rejected, state => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      sentBy: {
        isLoading: false,
        data: [],
      },
    },
  }));

giftsFilters
  .on(fetchCampaignsFilterData.pending, state => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      campaigns: {
        isLoading: true,
        data: [],
      },
    },
  }))
  .on(fetchCampaignsFilterData.fulfilled, (state, payload) => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      campaigns: {
        isLoading: false,
        data: payload,
      },
    },
  }))
  .on(fetchCampaignsFilterData.rejected, state => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      campaigns: {
        isLoading: false,
        data: [],
      },
    },
  }));

giftsFilters
  .on(fetchTeamsFilterData.pending, state => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      teams: {
        isLoading: true,
        data: [],
      },
    },
  }))
  .on(fetchTeamsFilterData.fulfilled, (state, payload) => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      teams: {
        isLoading: false,
        data: payload,
      },
    },
  }))
  .on(fetchTeamsFilterData.rejected, state => ({
    ...state,
    filtersData: {
      ...state.filtersData,
      teams: {
        isLoading: false,
        data: [],
      },
    },
  }));

giftsFilters.on(setSidebarIsOpen, (state, payload) => ({
  ...state,
  isSidebarOpened: payload,
}));
