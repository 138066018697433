import React, { memo } from 'react';

import { ConnectionsSection } from '../builder/blocks/ConnectionsSection';
import { RecipesSection } from '../builder/blocks/RecipesSection';
import DashboardLayout from '../../../../../../../components/Dashboard/Shared/DashboardLayout';
import StaticRecipe from '../builder/templates/StaticRecipe';
import msDynamicsIcon from '../../../../../../../assets/images/microsoft.svg';
import { WorkatoProviders } from '../../../../../store/organisation/integrations/workato/workato.types';

import {
  msDynamicsCreateRecipeConfig,
  msDynamicsClaimRecipeConfig,
  msDynamicsSentRecipeConfig,
  msDynamicsViewRecipeConfig,
} from './MsDynamicsRecipe/MsDynamicsConstants';

interface IMsDynamicsIntegrationProps {
  integrationId: string;
}

const MsDynamicsIntegration = ({ integrationId }: IMsDynamicsIntegrationProps) => (
  <DashboardLayout>
    <ConnectionsSection integrationId={integrationId} />
    <RecipesSection>
      <StaticRecipe
        recipeId={msDynamicsCreateRecipeConfig.id}
        title={msDynamicsCreateRecipeConfig.title}
        integratedAppLogoSrc={msDynamicsIcon}
        description={msDynamicsCreateRecipeConfig.description}
        tiedConnector={WorkatoProviders.Dynamics}
      />
      <StaticRecipe
        recipeId={msDynamicsSentRecipeConfig.id}
        title={msDynamicsSentRecipeConfig.title}
        integratedAppLogoSrc={msDynamicsIcon}
        description={msDynamicsSentRecipeConfig.description}
        tiedConnector={WorkatoProviders.Dynamics}
      />
      <StaticRecipe
        recipeId={msDynamicsViewRecipeConfig.id}
        title={msDynamicsViewRecipeConfig.title}
        integratedAppLogoSrc={msDynamicsIcon}
        description={msDynamicsViewRecipeConfig.description}
        tiedConnector={WorkatoProviders.Dynamics}
      />
      <StaticRecipe
        recipeId={msDynamicsClaimRecipeConfig.id}
        title={msDynamicsClaimRecipeConfig.title}
        integratedAppLogoSrc={msDynamicsIcon}
        description={msDynamicsClaimRecipeConfig.description}
        tiedConnector={WorkatoProviders.Dynamics}
      />
    </RecipesSection>
  </DashboardLayout>
);

export default memo(MsDynamicsIntegration);
