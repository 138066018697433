import { always, cond, equals, T } from 'ramda';
import { GiftActions } from '@alycecom/modules';

import { GiftActionsPayload } from '../types/Influitive.types';

export function mapGiftActionsFomGiftOption(giftAction: GiftActions): GiftActionsPayload {
  const mapper = cond([
    [equals(GiftActions.AcceptOrExchange), always({ accept: 1, exchange: 1, donate: 0 })],
    [equals(GiftActions.OnlyAccept), always({ accept: 1, exchange: 0, donate: 0 })],
    [T, always({ accept: 0, exchange: 0, donate: 0 })],
  ]) as (giftAction: GiftActions) => GiftActionsPayload;

  return mapper(giftAction);
}

export function mapGiftOptionFromGiftAction(giftActionOption: GiftActionsPayload): GiftActions {
  const mapper = cond([
    [equals(mapGiftActionsFomGiftOption(GiftActions.AcceptOrExchange)), always(GiftActions.AcceptOrExchange)],
    [equals(mapGiftActionsFomGiftOption(GiftActions.OnlyAccept)), always(GiftActions.OnlyAccept)],
    [T, always(GiftActions.AcceptOrExchange)],
  ]) as (giftAction: GiftActionsPayload) => GiftActions;

  return mapper(giftActionOption);
}
