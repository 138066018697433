import { Epic } from 'redux-observable';
import { ofType } from '@alycecom/utils';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { GlobalMessage, handleError, handlers, MessageType } from '@alycecom/services';
import { CampaignSettings } from '@alycecom/modules';
import { applySpec, compose, propOr } from 'ramda';

import { goToNextStep } from '../../ui/activeStep/activeStep.actions';
import { getDetailsData } from '../details/details.selectors';
import { TEMPLATE_QUERY_PARAMS } from '../../../../constants/messaging';

import { createTeamTemplate, updateDraftProspectingMessaging, updateProspectingMessaging } from './messaging.actions';
import { ITemplate } from './messaging.types';

const updateDraftProspectingMessagingEpic: Epic = (action$, state$, { apiService }) =>
  action$.pipe(
    ofType(updateDraftProspectingMessaging.pending),
    withLatestFrom(state$),
    switchMap(([{ payload: { id, ...body } }, state]) =>
      apiService.put(`/api/v1/campaigns/prospecting/drafts/${id}/messaging`, { body }, true).pipe(
        mergeMap(() => [
          updateDraftProspectingMessaging.fulfilled(body),
          GlobalMessage.messagesService.showGlobalMessage({
            type: MessageType.Success,
            text: `"${getDetailsData(state)?.campaignName}" updated successfully!`,
          }),
          goToNextStep(),
        ]),
        catchError(handleError(handlers.handleAnyError(updateDraftProspectingMessaging.rejected))),
      ),
    ),
  );

const updateProspectingMessagingEpic: Epic = (action$, state$, { apiService }) =>
  action$.pipe(
    ofType(updateProspectingMessaging.pending),
    withLatestFrom(state$),
    switchMap(([{ payload: { id, ...body } }, state]) =>
      apiService.put(`/api/v1/campaigns/prospecting/${id}/messaging`, { body }, true).pipe(
        mergeMap(() => [
          updateProspectingMessaging.fulfilled(body),
          GlobalMessage.messagesService.showGlobalMessage({
            type: MessageType.Success,
            text: `"${getDetailsData(state)?.campaignName}" updated successfully!`,
          }),
        ]),
        catchError(handleError(handlers.handleAnyError(updateProspectingMessaging.rejected))),
      ),
    ),
  );

const createTeamTemplateEpic: Epic = (action$, state$, { apiService }) =>
  action$.pipe(
    ofType(createTeamTemplate.pending),
    withLatestFrom(state$.pipe(map(applySpec({ teamId: compose(propOr(null, 'teamId'), getDetailsData) })))),
    switchMap(([{ payload }, { teamId }]) =>
      apiService.post(`/enterprise/dashboard/settings/teams/${teamId}/templates/create`, { body: payload }).pipe(
        mergeMap(({ template }: { template: ITemplate }) => [
          createTeamTemplate.fulfilled(template),
          GlobalMessage.messagesService.showGlobalMessage({
            type: MessageType.Success,
            text: 'Template successfully created!',
          }),
        ]),
        catchError(err => handleError(handlers.handleAnyError(createTeamTemplate.rejected(err.errors)))(err)),
      ),
    ),
  );

const refreshTeamTemplateList: Epic = (action$, state$) =>
  action$.pipe(
    ofType(createTeamTemplate.fulfilled),
    withLatestFrom(state$.pipe(map(applySpec({ teamId: compose(propOr(null, 'teamId'), getDetailsData) })))),
    map(([, { teamId }]) =>
      CampaignSettings.actions.fetchMessageTemplatesByTeamId({
        teamId: teamId as number,
        queryParams: TEMPLATE_QUERY_PARAMS,
      }),
    ),
  );

export default [
  updateDraftProspectingMessagingEpic,
  updateProspectingMessagingEpic,
  createTeamTemplateEpic,
  refreshTeamTemplateList,
];
