import React, { memo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { BudgetCreateField, TBudgetCreateParams, FiscalYear } from '@alycecom/services';
import { Box, FormControl, FormHelperText, MenuItem, Select, Theme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IFiscalYearStartProps {
  control: Control<TBudgetCreateParams>;
  error?: string;
}

const styles = {
  renderValue: {
    color: ({ palette }: Theme) => palette.primary.main,
  },
} as const;

const getRenderValue = (value: FiscalYear): JSX.Element => (
  <Box sx={styles.renderValue}>{value ? FiscalYear[value] : 'Select the month when fiscal year starts'}</Box>
);

const fiscalYearOptions = Object.values(FiscalYear)
  .filter(fiscalYear => Number.isNaN(Number(fiscalYear)))
  .map(fiscalYearKey => ({ value: FiscalYear[fiscalYearKey as FiscalYear], label: fiscalYearKey }));

const FiscalYearStart = ({ control, error }: IFiscalYearStartProps): JSX.Element => (
  <Controller
    name={BudgetCreateField.FiscalYear}
    control={control}
    render={({ field: { onChange, value } }) => (
      <FormControl fullWidth variant="outlined" error={!!error}>
        <Select
          labelId="FiscalYearStart.Select.Label"
          id="FiscalYearStart.Select.Label"
          value={value ?? ''}
          onChange={onChange}
          data-testid="FiscalYearStart.Select"
          renderValue={getRenderValue}
          IconComponent={ExpandMoreIcon}
        >
          {fiscalYearOptions.map(fiscalYear => (
            <MenuItem
              key={fiscalYear.label}
              data-testid={`FiscalYearStartOption.${fiscalYear.label}`}
              value={fiscalYear.value}
            >
              {fiscalYear.label}
            </MenuItem>
          ))}
        </Select>
        {!!error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    )}
  />
);

export default memo(FiscalYearStart);
