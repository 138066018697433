import { Epic } from 'redux-observable';
import { catchError, flatMap, mergeMap, takeUntil } from 'rxjs/operators';
import { ofType } from '@alycecom/utils';

import { getTeamsFilter } from '../customerOrg';
import { getDateRange, getGroupId } from '../ui/overviewFilters/overviewFilters.selectors';

import { emailReportFailure, emailReportRequest, emailReportSuccess } from './breakdowns.actions';

export const emailReportEpic: Epic = (
  action$,
  state$,
  { apiService, messagesService: { errorHandler, showGlobalMessage } },
) =>
  action$.pipe(
    ofType(emailReportRequest),
    mergeMap(action => {
      const steps = { sendToEmail: action.payload };
      const { teamIds } = getTeamsFilter(state$.value);
      const groupIds = getGroupId(state$.value) === 'all_groups_and_teams' ? [] : [getGroupId(state$.value)];
      const dateRange = getDateRange(state$.value);
      const filters = {
        teamIds,
        groupIds,
        ...(dateRange.from && dateRange.to
          ? {
              spentPeriod: {
                from: `${dateRange.from}T00:00:00Z`,
                fromIncluded: true,
                to: `${dateRange.to}T23:59:59Z`,
                toIncluded: true,
              },
            }
          : {}),
        ...(dateRange.from && dateRange.to
          ? {
              acceptedPeriod: {
                from: `${dateRange.from}T00:00:00Z`,
                fromIncluded: true,
                to: `${dateRange.to}T23:59:59Z`,
                toIncluded: true,
              },
            }
          : {}),
      };
      return apiService
        .post('/api/v1/reporting/resources/report-request/billing-insights', { body: { steps, filters } }, true)
        .pipe(
          flatMap(() => [
            emailReportSuccess(),
            showGlobalMessage({ text: 'Your request successfully queued', type: 'success' }),
          ]),
          takeUntil(action$.ofType(emailReportRequest)),
          catchError(errorHandler({ callbacks: emailReportFailure, showErrorsAsGlobal: true })),
        );
    }),
  );

export default [emailReportEpic];
