export const BULK_UPLOAD_FILE_UPLOAD_REQUEST = 'BULK_UPLOAD_FILE_UPLOAD_REQUEST';
export const BULK_UPLOAD_FILE_UPLOAD_SUCCESS = 'BULK_UPLOAD_FILE_UPLOAD_SUCCESS';
export const BULK_UPLOAD_FILE_UPLOAD_FAIL = 'BULK_UPLOAD_FILE_UPLOAD_FAIL';
export const BULK_UPLOAD_RESET_PREVIEW = 'BULK_UPLOAD_RESET_PREVIEW';
export const BULK_UPLOAD_RESET = 'BULK_UPLOAD_RESET';
export const BULK_IMPORT_UPDATE_CONTACT = 'BULK_IMPORT_UPDATE_CONTACT';
export const BULK_IMPORT_REMOVE_CONTACT = 'BULK_IMPORT_REMOVE_CONTACT';
export const BULK_UPLOAD_DOWNLOAD_TEMPLATE = 'BULK_UPLOAD_DOWNLOAD_TEMPLATE_REQUEST';
export const BULK_UPLOAD_DOWNLOAD_TEMPLATE_SUCCESS = 'BULK_UPLOAD_DOWNLOAD_TEMPLATE_SUCCESS';
export const BULK_IMPORT_AVAILABLE_CAMPAIGNS_FOR_TEAM_REQUEST = 'BULK_IMPORT_AVAILABLE_CAMPAIGNS_FOR_TEAM_REQUEST';
export const BULK_IMPORT_AVAILABLE_CAMPAIGNS_FOR_TEAM_SUCCESS = 'BULK_IMPORT_AVAILABLE_CAMPAIGNS_FOR_TEAM_SUCCESS';
export const BULK_IMPORT_REQUEST = 'BULK_IMPORT_REQUEST';
export const BULK_IMPORT_SUCCESS = 'BULK_IMPORT_SUCCESS';
export const BULK_IMPORT_VALIDATION_ERRORS = 'BULK_IMPORT_VALIDATION_ERRORS';
export const BULK_IMPORT_FAIL = 'BULK_IMPORT_FAIL';
export const BULK_IMPORT_FINISH = 'BULK_IMPORT_FINISH';

export const BULK_CREATE = {
  GET_TEAMS: 'ED/BULK_CREATE/GET_TEAMS_REQUEST',
  GET_TEAMS_SUCCESS: 'ED/BULK_CREATE/GET_TEAMS_SUCCESS',
  WAIT_FOR_IMPORT_START: 'ED/BULK_CREATE/WAIT_FOR_IMPORT_START',
  WAIT_FOR_IMPORT_UPDATE: 'ED/BULK_CREATE/WAIT_FOR_IMPORT_UPDATE',
  WAIT_FOR_IMPORT_CANCEL: 'ED/BULK_CREATE/WAIT_FOR_IMPORT_CANCEL',
  WAIT_FOR_IMPORT_FAIL: 'ED/BULK_CREATE/WAIT_FOR_IMPORT_FAIL',
};
