import React, { memo, useCallback } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button as ActionButton,
} from '@mui/material';
import { Button, TableLoadingLabel } from '@alycecom/ui';
import { TCampaign, TGetCampaignsResponse } from '@alycecom/services';
import { makeStyles } from '@mui/styles';
import { GIFT_ACTIONS_LIST } from '@alycecom/modules/dist/SalesIntegrationsModule/gifting/gifting.constants';
import { applySpec, compose, prop, propEq, propOr } from 'ramda';
import { useRouting } from '@alycecom/hooks';

import { mapGiftOptionFromGiftAction } from '../../../../../../../InfluitiveModule/helpers/influitive.helpers';

export interface InfluitiveCampaignsTableProps {
  isLoading: boolean;
  getCampaignsResponse: TGetCampaignsResponse | undefined;
}

const useStyles = makeStyles(({ palette }) => ({
  cell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  viewCampaignButton: {
    color: palette.link.main,
    borderColor: palette.link.main,
  },
}));

const InfluitiveCampaignsTable = ({ isLoading, getCampaignsResponse }: InfluitiveCampaignsTableProps) => {
  const go = useRouting();
  const campaigns = getCampaignsResponse?.data || [];
  const classes = useStyles();
  const findGiftAction = compose(
    propOr('', 'label'),
    giftAction => GIFT_ACTIONS_LIST.find(propEq('id', giftAction)),
    mapGiftOptionFromGiftAction,
    applySpec({
      accept: compose(Number, prop('accept')) as (action: TCampaign['giftActions']) => 0 | 1,
      exchange: compose(Number, prop('exchange')) as (action: TCampaign['giftActions']) => 0 | 1,
      donate: compose(Number, prop('donate')) as (action: TCampaign['giftActions']) => 0 | 1,
    }),
  ) as (giftAction: TCampaign['giftActions']) => string;
  const navigateToEdit = useCallback(
    (campaignId: number) => go(`/campaign/editor/${campaignId}/settings-and-permissions/general`),
    [go],
  );
  const navigateToView = useCallback((campaignId: number) => go(`/campaigns/${campaignId}`), [go]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.cell}>CAMPAIGN NAME</TableCell>
          <TableCell className={classes.cell}>GIFT TYPE</TableCell>
          <TableCell className={classes.cell}>EXCHANGE OPTION</TableCell>
          <TableCell className={classes.cell}>CAMPAIGN ID</TableCell>
          <TableCell className={classes.cell}> </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {campaigns.map(campaign => (
          <TableRow key={campaign.id}>
            <TableCell className={classes.cell}>
              <TableLoadingLabel
                render={() => (
                  <Box>
                    <Button variant="text" onClick={() => navigateToView(campaign.id)}>
                      {campaign.name}
                    </Button>
                  </Box>
                )}
                isLoading={isLoading}
              />
            </TableCell>
            <TableCell className={classes.cell}>
              <TableLoadingLabel render={() => <Typography>Gift Cards</Typography>} isLoading={isLoading} />
            </TableCell>
            <TableCell className={classes.cell}>
              <TableLoadingLabel
                render={() => (
                  <Box>
                    <Typography>{findGiftAction(campaign.giftActions)}</Typography>
                  </Box>
                )}
                isLoading={isLoading}
              />
            </TableCell>
            <TableCell className={classes.cell}>
              <TableLoadingLabel
                render={() => (
                  <Box>
                    <Typography>{campaign.id}</Typography>
                  </Box>
                )}
                isLoading={isLoading}
              />
            </TableCell>
            <TableCell className={classes.cell}>
              <TableLoadingLabel
                render={() => (
                  <Box>
                    <ActionButton
                      className={classes.viewCampaignButton}
                      variant="outlined"
                      onClick={() => navigateToEdit(campaign.id)}
                    >
                      Edit Campaign
                    </ActionButton>
                  </Box>
                )}
                isLoading={isLoading}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default memo(InfluitiveCampaignsTable);
