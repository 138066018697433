// @ts-nocheck
import React, { useMemo, useState, useEffect, useCallback, ReactElement, memo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AlyceLoading,
  Merchant,
  MerchantHeader,
  ProductSidebar,
  ProductSidebarHeader,
  ProductSidebarTitle,
  ProductDetailsWrapper,
  IProduct,
} from '@alycecom/ui';
import { TrackEvent } from '@alycecom/services';
import { useUrlQuery } from '@alycecom/hooks';

import { getIsLoading, getProduct } from '../../store/productDetails/productDetails.selectors';
import { loadProductDetailsRequest, resetProductDetails } from '../../store/productDetails/productDetails.actions';
import { useGetSenderMarketplaceGeneralPayload } from '../../hooks/useTrackSenderMarketplace';

import Product from './Product/Product';

export interface IProductDetailsSidebarProps {
  isOpen: boolean;
  minPrice: number | null;
  maxPrice: number | null;
  donationMinPrice?: number | null;
  donationMaxPrice?: number | null;
  giftCardMinPrice?: number | null;
  giftCardMaxPrice?: number | null;
  product?: IProduct;
  onClose: () => void;
  onChoose: (product: IProduct) => void;
}

const ProductDetailsWithRange = ({
  isOpen,
  product,
  onClose,
  onChoose,
  minPrice = null,
  maxPrice = null,
  donationMinPrice = null,
  donationMaxPrice = null,
  giftCardMinPrice = null,
  giftCardMaxPrice = null,
}: IProductDetailsSidebarProps): ReactElement => {
  const dispatch = useDispatch();
  const [isMerchantSidebar, setIsMerchantSidebar] = useState(false);
  const { batchId: batchIdFromUrl } = useUrlQuery<{ batchId: string }>(['batchId']);
  const isLoading = useSelector(getIsLoading);
  const productDetails = useSelector(getProduct);
  const { trackEvent } = TrackEvent.useTrackEvent();
  const generalPayload = useGetSenderMarketplaceGeneralPayload();

  const handleToggleMerchant = useCallback(() => {
    setIsMerchantSidebar(isMerchantView => !isMerchantView);
  }, []);

  const productDetailsHeader = useMemo(() => {
    if (!productDetails) {
      return <></>;
    }
    const headerComponent = isMerchantSidebar ? (
      <MerchantHeader title="Merchant details" logo={productDetails?.provider.logo} onBack={handleToggleMerchant} />
    ) : (
      <ProductSidebarTitle title="Product details" />
    );
    return <ProductSidebarHeader onClose={onClose}>{headerComponent}</ProductSidebarHeader>;
  }, [handleToggleMerchant, productDetails, isMerchantSidebar, onClose]);

  const detailsOpened = useRef(false);
  useEffect(() => {
    if (isOpen && product) {
      const batchId = parseInt(batchIdFromUrl, 10);
      dispatch(
        loadProductDetailsRequest({
          productId: product.id,
          localPrice: product.denomination,
          showRangeDenomination: product.hasRangeDenomination,
          minPrice,
          maxPrice,
          donationMinPrice,
          donationMaxPrice,
          giftCardMinPrice,
          giftCardMaxPrice,
          batchId,
        }),
      );
    } else {
      dispatch(resetProductDetails());
      setIsMerchantSidebar(false);
      detailsOpened.current = false;
    }
  }, [
    isOpen,
    product,
    dispatch,
    detailsOpened,
    minPrice,
    maxPrice,
    donationMinPrice,
    donationMaxPrice,
    giftCardMinPrice,
    giftCardMaxPrice,
    batchIdFromUrl,
  ]);

  useEffect(() => {
    const { current: isMounted } = detailsOpened;
    if (isOpen && product && !isMounted) {
      trackEvent('Marketplace product — opened details', { ...generalPayload, productId: product.id });
      detailsOpened.current = true;
    }
  }, [isOpen, product, generalPayload, trackEvent, detailsOpened]);

  return (
    <ProductSidebar isOpen={isOpen} onClose={onClose} header={productDetailsHeader}>
      <AlyceLoading isLoading={isLoading} boxProps={{ mt: 6 }}>
        <ProductDetailsWrapper hasProduct={!!productDetails}>
          {isMerchantSidebar ? (
            <Merchant product={productDetails} />
          ) : (
            <Product
              onChoose={onChoose}
              product={product}
              minPrice={minPrice}
              productDetails={productDetails}
              onShowMerchant={handleToggleMerchant}
            />
          )}
        </ProductDetailsWrapper>
      </AlyceLoading>
    </ProductSidebar>
  );
};

export default memo(ProductDetailsWithRange);
