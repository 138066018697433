import { createAction } from 'redux-act';

import {
  ORGANISATION_APPLICATIONS,
  ORGANISATION_APPLICATIONS_CREATE,
  ORGANISATION_APPLICATIONS_UPDATE,
  DYNAMICS_ORGANISATION_APPLICATIONS,
  DYNAMICS_ORGANISATION_APPLICATIONS_CREATE,
  POLLING_DYNAMICS_ORGANISATION_APPLICATIONS,
} from './organisationApplications.types';

export const pollingDynamicsOrganisationApplicationsRequest = createAction(
  POLLING_DYNAMICS_ORGANISATION_APPLICATIONS.REQUEST,
);
export const pollingDynamicsOrganisationApplicationsSuccess = createAction(
  POLLING_DYNAMICS_ORGANISATION_APPLICATIONS.SUCCESS,
);
export const pollingDynamicsOrganisationApplicationsFail = createAction(
  POLLING_DYNAMICS_ORGANISATION_APPLICATIONS.FAIL,
);

export const dynamicsOrganisationApplicationsRequest = createAction(DYNAMICS_ORGANISATION_APPLICATIONS.REQUEST);
export const dynamicsOrganisationApplicationsSuccess = createAction(DYNAMICS_ORGANISATION_APPLICATIONS.SUCCESS);
export const dynamicsOrganisationApplicationsFail = createAction(DYNAMICS_ORGANISATION_APPLICATIONS.FAIL);

export const dynamicsOrganisationApplicationsCreate = createAction(DYNAMICS_ORGANISATION_APPLICATIONS_CREATE.REQUEST);
export const dynamicsOrganisationApplicationsCreateSuccess = createAction(
  DYNAMICS_ORGANISATION_APPLICATIONS_CREATE.SUCCESS,
);
export const dynamicsOrganisationApplicationsCreateFail = createAction(DYNAMICS_ORGANISATION_APPLICATIONS_CREATE.FAIL);

/** organisation applications loading */
export const organisationApplicationsRequest = () => ({
  type: ORGANISATION_APPLICATIONS.REQUEST,
});

export const organisationApplicationsSuccess = applications => ({
  type: ORGANISATION_APPLICATIONS.SUCCESS,
  payload: applications,
});

export const organisationApplicationsFail = error => ({
  type: ORGANISATION_APPLICATIONS.FAIL,
  payload: error,
});

/** Create auth applications actions */
export const organisationApplicationsCreate = request => ({
  type: ORGANISATION_APPLICATIONS_CREATE.REQUEST,
  payload: request,
});
export const organisationApplicationsCreateSuccess = application => ({
  type: ORGANISATION_APPLICATIONS_CREATE.SUCCESS,
  payload: application,
});
export const organisationApplicationsCreateFail = errors => ({
  type: ORGANISATION_APPLICATIONS_CREATE.FAIL,
  payload: errors,
});

/** Update auth applications actions */
export const organisationApplicationsUpdate = applicationId => ({
  type: ORGANISATION_APPLICATIONS_UPDATE.REQUEST,
  payload: applicationId,
});
export const organisationApplicationsUpdateSuccess = request => ({
  type: ORGANISATION_APPLICATIONS_UPDATE.SUCCESS,
  payload: request,
});
export const organisationApplicationsUpdateFail = request => ({
  type: ORGANISATION_APPLICATIONS_UPDATE.FAIL,
  payload: request,
});
