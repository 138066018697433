import { createAsyncAction } from '@alycecom/utils';
import { TLegacyErrors } from '@alycecom/services';
import { createAction } from 'redux-act';

import { TProspectingMessaging } from '../../prospectingCampaign.types';

import { ICreateTemplate, ITemplate, TMessagingErrors } from './messaging.types';

const prefix = 'PROSPECTING_CAMPAIGN/STEPS/MESSAGING';

export const updateDraftProspectingMessaging = createAsyncAction<
  TProspectingMessaging & { id: number },
  TProspectingMessaging,
  TMessagingErrors
>(`${prefix}/UPDATE_DRAFT`);

export const updateProspectingMessaging = createAsyncAction<
  TProspectingMessaging & { id: number },
  TProspectingMessaging,
  TMessagingErrors
>(`${prefix}/UPDATE`);

export const resetTemplate = createAction(`${prefix}/RESET_TEMPLATE`);

export const createTeamTemplate = createAsyncAction<ICreateTemplate, ITemplate, TLegacyErrors<ITemplate>>(
  `${prefix}/CREAT0E_TEAM_TEMPLATE`,
);
