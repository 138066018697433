import React, { memo, useCallback, useEffect } from 'react';
import { TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Button, Icon } from '@alycecom/ui';
import { useCopyToClipboard } from 'react-use';
import { MessageType, showGlobalMessage } from '@alycecom/services';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import { IInfluitiveWebhook } from '../../../../../../store/organisation/integrations/influitive/influitive.types';
import { getInfluitiveWebhook } from '../../../../../../store/organisation/integrations/influitive/influitive.selectors';

import StepToConnect from './StepToConnect';

const useStyles = makeStyles(({ spacing, palette }) => ({
  form: {
    display: 'flex',
    gap: spacing(2),
  },
  field: {
    maxWidth: 456,
  },
  copy: {
    display: 'flex',
    gap: spacing(1),
  },
  icon: {
    color: palette.link.light,
  },
  webhook: {
    fontWeight: 700,
    lineHeight: 1.5,
    color: palette.link.light,
  },
}));

const CopyAlyceWebhook = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { control, getValues, setValue } = useForm<IInfluitiveWebhook>({
    mode: 'onSubmit',
    defaultValues: {
      webhookUrl: '',
    },
  });
  const [, copy] = useCopyToClipboard();
  const webhook = useSelector(getInfluitiveWebhook);
  const handleCopy = useCallback(() => {
    const { webhookUrl } = getValues();
    copy(webhookUrl);
    dispatch(showGlobalMessage({ type: MessageType.Good, text: 'Copied!' }));
  }, [getValues, copy, dispatch]);

  useEffect(() => {
    setValue('webhookUrl', webhook.webhookUrl);
  }, [setValue, webhook]);

  return (
    <StepToConnect
      id="CopyAlyceWebhook"
      title="2. Copy Alyce webhook"
      description="Head into Influitive > Settings > System > Webhooks and post this link"
    >
      <form className={classes.form}>
        <Controller
          control={control}
          render={({ field }) => (
            <TextField
              value={field.value}
              ref={field.ref}
              label="Alyce webhook"
              variant="outlined"
              inputProps={{ 'data-testid': 'CopyAlyceWebhook.AlyceWebhookField' }}
              fullWidth
              disabled
              className={classes.field}
            />
          )}
          name="webhookUrl"
        />
        <Button className={classes.copy} onClick={handleCopy} size="small" variant="text" type="button">
          <Icon className={classes.icon} icon={['far', 'copy']} />{' '}
          <Typography className={classes.webhook} data-testid="CopyAlyceWebhook.CopyWebhookButton">
            Copy webhook
          </Typography>
        </Button>
      </form>
    </StepToConnect>
  );
};

export default memo(CopyAlyceWebhook);
