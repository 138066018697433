import * as R from 'ramda';
import { NEVER, of, timer } from 'rxjs';
import { ofType } from 'redux-observable';
import { catchError, debounceTime, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { handleError, handlers } from '@alycecom/services';

import {
  dynamicsOrganisationApplicationsRequest,
  dynamicsOrganisationApplicationsFail,
  dynamicsOrganisationApplicationsSuccess,
  dynamicsOrganisationApplicationsCreate,
  dynamicsOrganisationApplicationsCreateFail,
  dynamicsOrganisationApplicationsCreateSuccess,
  pollingDynamicsOrganisationApplicationsRequest,
  pollingDynamicsOrganisationApplicationsFail,
  pollingDynamicsOrganisationApplicationsSuccess,
  organisationApplicationsCreateFail,
  organisationApplicationsCreateSuccess,
  organisationApplicationsFail,
  organisationApplicationsSuccess,
  organisationApplicationsUpdateFail,
  organisationApplicationsUpdateSuccess,
} from './organisationApplications.actions';
import {
  ORGANISATION_APPLICATIONS,
  ORGANISATION_APPLICATIONS_CREATE,
  ORGANISATION_APPLICATIONS_UPDATE,
} from './organisationApplications.types';

export const organisationApplicationEpic = (
  action$,
  state$,
  { apiService, messagesService: { errorHandlerWithGlobal } },
) =>
  action$.pipe(
    ofType(ORGANISATION_APPLICATIONS.REQUEST),
    debounceTime(300),
    mergeMap(() =>
      apiService.get('/auth/applications', null, true).pipe(
        map(response => organisationApplicationsSuccess(response.applications)),
        catchError(errorHandlerWithGlobal({ callbacks: organisationApplicationsFail })),
        takeUntil(action$.ofType(ORGANISATION_APPLICATIONS.REQUEST)),
      ),
    ),
  );

export const dynamicsOrganisationApplicationEpic = (action$, state$, { dynamicsApiService }) =>
  action$.pipe(
    ofType(dynamicsOrganisationApplicationsRequest),
    mergeMap(() =>
      dynamicsApiService.get('/dynamics/applications', null, true).pipe(
        map(response => dynamicsOrganisationApplicationsSuccess([response])),
        catchError(
          R.cond([
            // ignore 404 error
            [R.propEq('status', 404), () => of(dynamicsOrganisationApplicationsFail())],
            [R.T, handleError(handlers.handleAnyError(dynamicsOrganisationApplicationsFail))],
          ]),
        ),
        takeUntil(action$.ofType(dynamicsOrganisationApplicationsRequest)),
      ),
    ),
  );

export const pollingDynamicsOrganisationApplicationsRequestEpic = (
  action$,
  state$,
  { dynamicsApiService, messagesService: { showGlobalMessage } },
) =>
  action$.pipe(
    ofType(pollingDynamicsOrganisationApplicationsRequest),
    mergeMap(({ payload: newApplicationUrl }) =>
      timer(0, 3000).pipe(
        mergeMap(() =>
          dynamicsApiService.get('/dynamics/applications', null, true).pipe(
            switchMap(response => {
              if (response.crmUrl === newApplicationUrl) {
                return of(response);
              }

              return NEVER;
            }),
            mergeMap(response => [
              showGlobalMessage({ type: 'success', text: 'Dynamics App Created Successfully' }),
              pollingDynamicsOrganisationApplicationsSuccess([response]),
            ]),
            catchError(
              R.cond([
                // ignore 404 error
                [R.propEq('status', 404), () => of(pollingDynamicsOrganisationApplicationsFail())],
                [R.T, handleError(handlers.handleAnyError(pollingDynamicsOrganisationApplicationsFail))],
              ]),
            ),
          ),
        ),
        takeUntil(action$.ofType(pollingDynamicsOrganisationApplicationsSuccess)),
      ),
    ),
  );

export const organisationApplicationCreateEpic = (
  action$,
  state$,
  { apiService, messagesService: { errorHandlerWithGlobal, showGlobalMessage } },
) =>
  action$.pipe(
    ofType(ORGANISATION_APPLICATIONS_CREATE.REQUEST),
    debounceTime(300),
    mergeMap(({ payload }) =>
      apiService.post('/auth/applications/create', { body: payload }, true).pipe(
        mergeMap(response => [
          showGlobalMessage({
            text: 'Key generated! Head over to your Salesforce account to add your key and secret.',
            type: 'success',
          }),
          organisationApplicationsCreateSuccess(response),
        ]),
        catchError(errorHandlerWithGlobal({ callbacks: organisationApplicationsCreateFail })),
        takeUntil(action$.ofType(ORGANISATION_APPLICATIONS_CREATE.REQUEST)),
      ),
    ),
  );

export const dynamicsOrganisationApplicationCreateEpic = (action$, state$, { dynamicsApiService }) =>
  action$.pipe(
    ofType(dynamicsOrganisationApplicationsCreate),
    mergeMap(({ payload }) =>
      dynamicsApiService
        .post('/dynamics/applications', { body: payload }, true)
        .pipe(
          map(dynamicsOrganisationApplicationsCreateSuccess),
          catchError(handleError(handlers.handleAnyError(dynamicsOrganisationApplicationsCreateFail))),
          takeUntil(action$.ofType(dynamicsOrganisationApplicationsCreate)),
        ),
    ),
  );

export const organisationApplicationUpdateEpic = (
  action$,
  state$,
  { apiService, messagesService: { errorHandlerWithGlobal, showGlobalMessage } },
) =>
  action$.pipe(
    ofType(ORGANISATION_APPLICATIONS_UPDATE.REQUEST),
    debounceTime(300),
    mergeMap(({ payload }) =>
      apiService.put(`/auth/applications/${payload}/secret`, null, true).pipe(
        mergeMap(response => [
          showGlobalMessage({
            text: 'Key generated! Head over to your Salesforce account to add your key and secret.',
            type: 'success',
          }),
          organisationApplicationsUpdateSuccess(response),
        ]),
        catchError(errorHandlerWithGlobal({ callbacks: organisationApplicationsUpdateFail })),
        takeUntil(action$.ofType(ORGANISATION_APPLICATIONS_UPDATE.REQUEST)),
      ),
    ),
  );

export const organisationApplicationsEpics = [
  organisationApplicationEpic,
  organisationApplicationCreateEpic,
  organisationApplicationUpdateEpic,
  dynamicsOrganisationApplicationEpic,
  dynamicsOrganisationApplicationCreateEpic,
  pollingDynamicsOrganisationApplicationsRequestEpic,
];
