export const CREATE_NEW_CUSTOM_TEMPLATE = 'CREATE_NEW_CUSTOM_TEMPLATE';
export const DISCARD_NEW_CUSTOM_TEMPLATE = 'DISCARD_NEW_CUSTOM_TEMPLATE';
export const CREATE_NEW_CUSTOM_TEMPLATE_REQUEST = 'CREATE_NEW_CUSTOM_TEMPLATE_REQUEST';
export const CREATE_NEW_CUSTOM_TEMPLATE_SUCCESS = 'CREATE_NEW_CUSTOM_TEMPLATE_SUCCESS';
export const CREATE_NEW_CUSTOM_TEMPLATE_FAIL = 'CREATE_NEW_CUSTOM_TEMPLATE_FAIL';

export const ALL_CUSTOM_TEMPLATES_LOAD_REQUEST = 'ALL_CUSTOM_TEMPLATES_LOAD_REQUEST';
export const ALL_CUSTOM_TEMPLATES_LOAD_SUCCESS = 'ALL_CUSTOM_TEMPLATES_LOAD_SUCCESS';
export const ALL_CUSTOM_TEMPLATES_LOAD_FAIL = 'ALL_CUSTOM_TEMPLATES_LOAD_FAIL';

export const CUSTOM_TEMPLATE_LOAD_REQUEST = 'CUSTOM_TEMPLATE_LOAD_REQUEST';
export const CUSTOM_TEMPLATE_LOAD_SUCCESS = 'CUSTOM_TEMPLATE_LOAD_SUCCESS';
export const CUSTOM_TEMPLATE_LOAD_FAIL = 'CUSTOM_TEMPLATE_LOAD_FAIL';

export const UPDATE_CUSTOM_TEMPLATE_REQUEST = 'UPDATE_CUSTOM_TEMPLATE_REQUEST';
export const UPDATE_CUSTOM_TEMPLATE_SUCCESS = 'UPDATE_CUSTOM_TEMPLATE_SUCCESS';
export const UPDATE_CUSTOM_TEMPLATE_FAIL = 'UPDATE_CUSTOM_TEMPLATE_FAIL';

export const DISCARD_CUSTOM_TEMPLATE_UPDATE = 'DISCARD_CUSTOM_TEMPLATE_UPDATE';

export const DELETE_CUSTOM_TEMPLATE_REQUEST = 'DELETE_CUSTOM_TEMPLATE_REQUEST';
export const DELETE_CUSTOM_TEMPLATE_SUCCESS = 'DELETE_CUSTOM_TEMPLATE_SUCCESS';
export const DELETE_CUSTOM_TEMPLATE_FAIL = 'DELETE_CUSTOM_TEMPLATE_FAIL';
