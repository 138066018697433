import { createReducer } from 'redux-act';
import { createEntityAdapter, StateStatus } from '@alycecom/utils';
import { IOffsetPagination } from '@alycecom/services';
import { uniq, without } from 'ramda';

import { IGiftsBreakdownListItem, IGiftsBreakdownResponseMeta } from './giftsBreakdown.types';
import {
  toggleSelection,
  resetSelection,
  fetchGiftsRequest,
  fetchGiftsSuccess,
  fetchGiftsFail,
} from './giftsBreakdown.actions';

export interface IAdditionalCampaignManagementState {
  status: StateStatus;
  pagination: IOffsetPagination;
  meta: IGiftsBreakdownResponseMeta;
  selected: IGiftsBreakdownListItem[];
}

export const giftsManagementAdapter = createEntityAdapter<IGiftsBreakdownListItem>();

export const initialState = giftsManagementAdapter.getInitialState<IAdditionalCampaignManagementState>({
  status: StateStatus.Idle,
  pagination: {
    total: 0,
    offset: 0,
    limit: 0,
  },
  meta: {
    totalAll: NaN,
    accepted: NaN,
    expired: NaN,
    readyToSent: NaN,
    sent: NaN,
    viewed: NaN,
  },
  selected: [],
});

export type IGiftsManagementState = typeof initialState;

export const giftsBreakdown = createReducer({}, initialState)
  .on(fetchGiftsRequest, state => ({
    ...state,
    status: StateStatus.Pending,
  }))
  .on(fetchGiftsSuccess, (state, payload) => ({
    ...state,
    ...giftsManagementAdapter.setAll(payload.gifts, state),
    status: StateStatus.Fulfilled,
    pagination: payload.pagination,
    meta: payload.meta,
  }))
  .on(fetchGiftsFail, state => ({
    ...state,
    status: StateStatus.Rejected,
  }))

  .on(toggleSelection, (state, payload) => ({
    ...state,
    selected: payload.checked ? uniq([...state.selected, ...payload.gifts]) : without(payload.gifts, state.selected),
  }))
  .on(resetSelection, state => ({
    ...state,
    selected: [],
  }));
