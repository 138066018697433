import { Epic, ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { applySpec } from 'ramda';
import { User } from '@alycecom/modules';
import { handleError, handlers, MessageType } from '@alycecom/services';

import { IRootState } from '../../../../../../store/root.types';

import { loadOrganizationInfluitiveIntegration, updateOrganizationInfluitiveIntegration } from './influitive.actions';
import { InfluitiveWebhookResponse } from './influitive.types';
import { getIsInfluitiveWebhookAvailable } from './influitive.selectors';

export const loadOrganizationInfluitiveIntegrationEpic: Epic = (action$, state$, { apiGateway }) =>
  action$.pipe(
    ofType(loadOrganizationInfluitiveIntegration.pending),
    withLatestFrom(state$.pipe(map(applySpec({ orgId: User.selectors.getOrgId })))),
    switchMap(([, { orgId }]) =>
      apiGateway.get(`/influitive/integration/${orgId}`, null, true).pipe(
        map((response: InfluitiveWebhookResponse) => loadOrganizationInfluitiveIntegration.fulfilled(response.data)),
        catchError(handleError(handlers.handleAnyError(loadOrganizationInfluitiveIntegration.rejected()))),
      ),
    ),
  );

export const updateOrganizationInfluitiveIntegrationEpic: Epic = (
  action$,
  state$,
  { apiGateway, messagesService: { errorHandler, showGlobalMessage } },
) =>
  action$.pipe(
    ofType(updateOrganizationInfluitiveIntegration.pending),
    withLatestFrom(
      state$.pipe(
        map(
          applySpec({
            orgId: User.selectors.getOrgId,
            isEdit: getIsInfluitiveWebhookAvailable,
          }) as (rootState: IRootState) => { orgId: number; isEdit: boolean },
        ),
      ),
    ),
    switchMap(([{ payload }, { orgId, isEdit }]) =>
      apiGateway.put(`/influitive/integration/${orgId}`, { body: payload }, true).pipe(
        mergeMap((response: InfluitiveWebhookResponse) => [
          updateOrganizationInfluitiveIntegration.fulfilled(response.data),
          showGlobalMessage({
            type: 'success',
            text: `Influitive connection has been ${isEdit ? 'updated' : 'created'}`,
          }),
        ]),
        catchError(
          handleError(
            errorHandler({
              callbacks: [
                updateOrganizationInfluitiveIntegration.rejected,
                showGlobalMessage({ type: MessageType.Error, text: 'Something went wrong. Please try again later.' }),
              ],
            }),
          ),
        ),
      ),
    ),
  );

export const influitiveEpics = [loadOrganizationInfluitiveIntegrationEpic, updateOrganizationInfluitiveIntegrationEpic];
