export const SWAG_DIGITAL_CODES_GENERATE_FLOW_WIZARD_REQUEST = 'SWAG_DIGITAL_CODES_GENERATE_FLOW_WIZARD_REQUEST';

export const SWAG_DIGITAL_CODES_CHANGE_STEP = 'SWAG_DIGITAL_CODES_CHANGE_STEP';
export const SWAG_DIGITAL_CODES_SET_STEP_DATA = 'SWAG_DIGITAL_CODES_SET_STEP_DATA';

export const SWAG_DIGITAL_GENERATE_CODES_REQUEST = 'SWAG_DIGITAL_GENERATE_CODES_REQUEST';
export const SWAG_DIGITAL_GENERATE_CODES_SUCCESS = 'SWAG_DIGITAL_GENERATE_CODES_SUCCESS';
export const SWAG_DIGITAL_GENERATE_CODES_FAIL = 'SWAG_DIGITAL_GENERATE_CODES_FAIL';

export const SWAG_DIGITAL_GENERATION_CODES_PROGRESS_REQUEST = 'SWAG_DIGITAL_GENERATION_CODES_PROGRESS_REQUEST';
export const SWAG_DIGITAL_GENERATION_CODES_PROGRESS_SUCCESS = 'SWAG_DIGITAL_GENERATION_CODES_PROGRESS_SUCCESS';
export const SWAG_DIGITAL_GENERATION_CODES_PROGRESS_FAIL = 'SWAG_DIGITAL_GENERATION_CODES_PROGRESS_FAIL';
export const SWAG_DIGITAL_GENERATION_CODES_PROGRESS_FINISH = 'SWAG_DIGITAL_GENERATION_CODES_PROGRESS_FINISH';

export const SWAG_DIGITAL_UPDATE_BATCHES_REQUEST = 'SWAG_DIGITAL_UPDATE_BATCHES_REQUEST';
export const SWAG_DIGITAL_UPDATE_BATCHES_SUCCESS = 'SWAG_DIGITAL_UPDATE_BATCHES_SUCCESS';
export const SWAG_DIGITAL_UPDATE_BATCHES_FAIL = 'SWAG_DIGITAL_UPDATE_BATCHES_FAIL';
