export const TEAM_GIFT_INVITES_SETTINGS_REQUEST = 'TEAM_GIFT_INVITES_SETTINGS_REQUEST';
export const TEAM_GIFT_INVITES_SETTINGS_SUCCESS = 'TEAM_GIFT_INVITES_SETTINGS_SUCCESS';
export const TEAM_GIFT_INVITES_SETTINGS_FAIL = 'TEAM_GIFT_INVITES_SETTINGS_FAIL';

export const TEAM_SETTINGS_GIFT_INVITES_TYPES_REQUEST = 'TEAM_SETTINGS_GIFT_INVITES_TYPES_REQUEST';
export const TEAM_SETTINGS_GIFT_INVITES_TYPES_SUCCESS = 'TEAM_SETTINGS_GIFT_INVITES_TYPES_SUCCESS';
export const TEAM_SETTINGS_GIFT_INVITES_TYPES_FAIL = 'TEAM_SETTINGS_GIFT_INVITES_TYPES_FAIL';

export const TEAM_SETTINGS_GIFT_INVITES_TYPES_UPDATE_REQUEST = 'TEAM_SETTINGS_GIFT_INVITES_TYPES_UPDATE_REQUEST';
export const TEAM_SETTINGS_GIFT_INVITES_TYPES_UPDATE_SUCCESS = 'TEAM_SETTINGS_GIFT_INVITES_TYPES_UPDATE_SUCCESS';
export const TEAM_SETTINGS_GIFT_INVITES_TYPES_UPDATE_FAIL = 'TEAM_SETTINGS_GIFT_INVITES_TYPES_UPDATE_FAIL';

export const TEAM_SETTINGS_GIFT_INVITES_VENDORS_REQUEST = 'TEAM_SETTINGS_GIFT_INVITES_VENDORS_REQUEST';
export const TEAM_SETTINGS_GIFT_INVITES_VENDORS_SUCCESS = 'TEAM_SETTINGS_GIFT_INVITES_VENDORS_SUCCESS';
export const TEAM_SETTINGS_GIFT_INVITES_VENDORS_FAIL = 'TEAM_SETTINGS_GIFT_INVITES_VENDORS_FAIL';

export const TEAM_SETTINGS_GIFT_INVITES_VENDORS_UPDATE_REQUEST = 'TEAM_SETTINGS_GIFT_INVITES_VENDORS_UPDATE_REQUEST';
export const TEAM_SETTINGS_GIFT_INVITES_VENDORS_UPDATE_SUCCESS = 'TEAM_SETTINGS_GIFT_INVITES_VENDORS_UPDATE_SUCCESS';
export const TEAM_SETTINGS_GIFT_INVITES_VENDORS_UPDATE_FAIL = 'TEAM_SETTINGS_GIFT_INVITES_VENDORS_UPDATE_FAIL';
