import { createAction } from 'redux-act';
import { createAsyncAction } from '@alycecom/utils';

import { IFilterItem, IGiftsFilters } from './filters.types';

const PREFIX = 'GIFTS/FILTERS';

export const setFilters = createAction<Partial<IGiftsFilters>>(`${PREFIX}/SET_FILTERS`);

export const resetFilters = createAction(`${PREFIX}/RESET_FILTERS`);

export const fetchSentByFilterData = createAsyncAction<void, IFilterItem[]>(`${PREFIX}/FETCH_SENT_BY_DATA`);

export const fetchCampaignsFilterData = createAsyncAction<void, IFilterItem[]>(`${PREFIX}/FETCH_CAMPAIGNS_DATA`);

export const fetchTeamsFilterData = createAsyncAction<void, IFilterItem[]>(`${PREFIX}/FETCH_TEAMS_DATA`);

export const setSidebarIsOpen = createAction<boolean>(`${PREFIX}/SET_SIDEBAR_IS_OPEN`);
