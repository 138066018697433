// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AlyceTheme, IProductDetails, SelectFilter } from '@alycecom/ui';
import { useSelector } from 'react-redux';
import { CreateGift } from '@alycecom/modules';
import { IGiftCardRange } from '@alycecom/ui/dist/components/Marketplace/MarketplaceProduct/MarketplaceProduct';

const useStyles = makeStyles<AlyceTheme>(({ palette }) => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    borderTop: `1px solid ${palette.dividerLight}`,
  },
  menuItem: {
    display: 'flex',
  },
  costItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export interface IProductPriceDropdownProps {
  product?: IProductDetails & { giftCardRange?: IGiftCardRange; hasRangeDenomination?: boolean };
  onChange: (value: number) => void;
}

const ProductPriceDropdown = ({ product, onChange }: IProductPriceDropdownProps): JSX.Element => {
  const classes = useStyles();
  const optionsList = useMemo(
    () =>
      product?.giftCardRange?.denominations.map((price: number) => ({
        label: `${price}${product?.giftCardRange?.currencySign}`,
        value: price,
      })) || [],
    [product],
  );
  // const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const convertedPrice: {
    fromCurrencyId: number;
    toCurrencyId: number;
    price: number;
    convertedPrice: number;
  }[] = useSelector(CreateGift.selectors.getMarketplaceConvertedPrices);
  const minValue = product?.giftCardRange?.minPrice || 0;
  const [localValue, setLocalValue] = useState<number>(minValue);

  const getExchangePrice = useCallback(
    (value: number) =>
      convertedPrice
        ? convertedPrice?.find((item: { price: number }) => item.price === value)?.convertedPrice || null
        : null,
    [convertedPrice],
  );

  useEffect(() => {
    onChange(minValue);
  }, [onChange, minValue]);

  // useEffect(() => {
  //   dispatch(CreateGift.actions.setValueRangedProduct(minValue));
  //   if (product?.giftCardRange?.currencyId !== 1) {
  //     dispatch(
  //       CreateGift.actions.loadExchangeRatesRequest(
  //         product?.giftCardRange?.denominations.map(price => ({
  //           fromCurrencyId: product?.giftCardRange?.currencyId,
  //           toCurrencyId: 1,
  //           price,
  //         })),
  //       ),
  //     );
  //   }
  // }, [dispatch, minValue, product]);

  const handleChange = useCallback(
    ({ price }) => {
      setLocalValue(price);
      onChange(price);
    },
    [onChange],
  );

  return (
    <Box className={classes.headerWrapper}>
      <SelectFilter
        fullWidth
        margin="normal"
        onFilterChange={handleChange}
        renderItems={() =>
          optionsList?.map(price => (
            <MenuItem key={price.value} value={price.value} className={classes.menuItem}>
              <Grid container className={classes.costItem}>
                <Grid item>{price.label}</Grid>
                {product?.giftCardRange?.currencyId !== 1 && <Grid item>{getExchangePrice(price.value)}$</Grid>}
              </Grid>
            </MenuItem>
          ))
        }
        value={localValue}
        name="price"
        label=""
      />
    </Box>
  );
};

export default memo<IProductPriceDropdownProps>(ProductPriceDropdown);
