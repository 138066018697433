import { equals, pipe, prop } from 'ramda';
import { StateStatus } from '@alycecom/utils';

import { getOrganisationState } from '../../organisation.selectors';

const getOrganisationIntegrations = pipe(getOrganisationState, prop('integrations'));

const getOrganizationInfluitive = pipe(getOrganisationIntegrations, prop('influitive'));

const getInfluitiveState = pipe(getOrganizationInfluitive, prop('state'));

export const getInfluitiveIsLoading = pipe(getInfluitiveState, equals(StateStatus.Pending));

export const getInfluitiveIsIdle = pipe(getInfluitiveState, equals(StateStatus.Idle));

export const getInfluitiveWebhook = pipe(getOrganizationInfluitive, prop('webhook'));

export const getInfluitiveErrors = pipe(getOrganizationInfluitive, prop('errors'));

export const getIsInfluitiveWebhookAvailable = pipe(getOrganizationInfluitive, prop('isConnectionAvailable'));
