import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import PropTypes from 'prop-types';
import { TrackEvent } from '@alycecom/services';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ActionButton, BaseButton, BaseField, DashboardIcon, LinkButton, ModalConfirmationMessage } from '@alycecom/ui';
import { useRouting } from '@alycecom/hooks';

import { DISPLAY_DATE_TIME_FORMAT } from '../../../../../../constants/dateFormats.constants';
import {
  dynamicsOrganisationApplicationsRequest,
  dynamicsOrganisationApplicationsCreate,
  pollingDynamicsOrganisationApplicationsRequest,
} from '../../../../store/organisation/applications/organisationApplications.actions';
import {
  getApplications,
  getIsLoading,
} from '../../../../store/organisation/applications/organisationApplications.selectors';
import DashboardLayout from '../../../../../../components/Dashboard/Shared/DashboardLayout';
import {
  disconnectFromOAuth,
  loadOAuthState,
  startLoadOauthFlowLink,
  loadOauthStatePolling,
} from '../../../../store/organisation/integrations/dynamics/dynamics.actions';
import {
  getConnectedBy,
  getOAuthConnectedAt,
  getOauthErrorMessage,
  getOAuthLink,
  getOAuthState,
} from '../../../../store/organisation/integrations/dynamics/dynamics.selectors';
import { ConnectionState } from '../../../../store/organisation/integrations/dynamics/dynamics.types';
import { Integrations } from '../../Integrations/InHouseIntegrations/models/IntegrationsModels';

const useStyles = makeStyles(({ spacing, palette }) => ({
  icon: {
    marginRight: spacing(1),
    fontSize: '1rem',
  },
  docsLink: {
    display: 'inline-block',
    marginLeft: spacing(1),
  },
  noteBlock: {
    backgroundColor: palette.green.fruitSaladLight,
    height: 58,
  },
  warningBlock: {
    backgroundColor: palette.error.light,
    height: 58,
  },
  copyLink: {
    marginLeft: spacing(2),
  },
  secretField: {
    marginTop: spacing(2),
  },
  disconnectedLabel: {
    color: palette.error.main,
    marginLeft: spacing(1),
    fontSize: '16px',
    lineHeight: '1.5',
  },
  connectedLabel: {
    color: palette.green.fruitSalad,
    marginLeft: spacing(1),
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '1.5',
  },
}));

const datetimePipe = datestring => {
  const localMinuteOffset = moment().utcOffset();

  return moment(datestring).add(localMinuteOffset, 'm').format(DISPLAY_DATE_TIME_FORMAT);
};

const OrganisationApplicationsDynamics = ({ parentUrl }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const go = useRouting();
  const applications = useSelector(getApplications);
  const isApplicationsLoading = useSelector(getIsLoading);

  const oAuthState = useSelector(getOAuthState);

  const connectedByEmail = useSelector(getConnectedBy);
  const oAuthConnectedAt = useSelector(getOAuthConnectedAt);
  const oAuthLink = useSelector(getOAuthLink);
  const { trackEvent } = TrackEvent.useTrackEvent();
  const oAuthErrorMessage = useSelector(getOauthErrorMessage);
  const [oAuthDisconnectPopupShown, setOAuthDisconnectPopupShown] = useState(false);
  const [oAuthErrorPopupShown, setOAuthErrorPopupShown] = useState(false);

  const application = useMemo(() => {
    const dynamicsApps = R.filter(R.propEq('type', Integrations.Dynamics), applications);
    return dynamicsApps[0];
  }, [applications]);

  const activeApplicationUrl = application?.crmUrl;
  const connectedBy = useMemo(() => (connectedByEmail ? ` by ${connectedByEmail}` : ''), [connectedByEmail]);

  const oAuthDisconnect = useCallback(() => {
    dispatch(disconnectFromOAuth());
  }, [dispatch]);

  const startOauthFlow = useCallback(() => {
    window.open(oAuthLink, '_blank');
    dispatch(loadOauthStatePolling());

    trackEvent('Enterprise Dashboard - Clicked Connect to Dynamics button');
  }, [oAuthLink, trackEvent, dispatch]);

  useEffect(() => {
    if (application) {
      dispatch(startLoadOauthFlowLink());
    }
  }, [dispatch, application]);

  useEffect(() => {
    dispatch(dynamicsOrganisationApplicationsRequest());
    dispatch(loadOAuthState());
  }, [dispatch]);

  const [dynamicsAppUrl, setDynamicsAppUrl] = useState('');
  const [isAppUrlValid, setIsAppUrlValid] = useState(true);
  const [isNewAppConfirmationOpen, setIsNewAppConfirmationOpen] = useState(false);

  const connectDynamicsApplication = useCallback(() => {
    // application create is a process
    dispatch(dynamicsOrganisationApplicationsCreate({ type: Integrations.Dynamics, crmUrl: dynamicsAppUrl }));
    // we need to wait until application is created
    // until need to continuously fetch applications
    dispatch(pollingDynamicsOrganisationApplicationsRequest(dynamicsAppUrl));
  }, [dispatch, dynamicsAppUrl]);

  const isApplicationConnected = oAuthState === ConnectionState.Connected;
  const isApplicationExist = application !== undefined;

  const onSyncDynamicsAppUrlClick = useCallback(() => {
    if (dynamicsAppUrl === '') return;

    const urlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}|[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}|[a-zA-Z0-9-]+\.[a-zA-Z]{2,6})([:/?#].*)?$/;
    const isValidUrl = urlRegex.test(dynamicsAppUrl);

    if (!isValidUrl) {
      setIsAppUrlValid(false);
      return;
    }
    if (!isAppUrlValid) {
      setIsAppUrlValid(true);
    }

    if (isApplicationExist || isApplicationConnected) {
      setIsNewAppConfirmationOpen(true);
      return;
    }

    connectDynamicsApplication();
  }, [dynamicsAppUrl, isAppUrlValid, isApplicationExist, isApplicationConnected, connectDynamicsApplication]);

  const onDisconnectClick = useCallback(() => {
    setOAuthDisconnectPopupShown(true);
  }, []);

  const onCreateNewAppConfirmationSubmit = useCallback(() => {
    oAuthDisconnect();
    connectDynamicsApplication();

    setIsNewAppConfirmationOpen(false);
  }, [oAuthDisconnect, connectDynamicsApplication]);
  const onCreateNewAppConfirmationDiscard = useCallback(() => {
    setIsNewAppConfirmationOpen(false);
  }, []);

  const onDisconnectAppConfirmSubmit = useCallback(() => {
    setOAuthDisconnectPopupShown(false);
    oAuthDisconnect();
  }, [oAuthDisconnect]);
  const onDisconnectAppConfirmDiscard = useCallback(() => {
    setOAuthDisconnectPopupShown(false);
  }, []);

  const onErrorConfirmSubmit = useCallback(() => {
    setOAuthErrorPopupShown(false);
  }, []);

  return (
    <DashboardLayout>
      <Box mb={3}>
        <LinkButton onClick={() => go(parentUrl)}>
          <DashboardIcon icon="arrow-left" color="link" className={classes.icon} />
          Back to all integrations
        </LinkButton>
      </Box>
      <Box mb={2}>
        <Typography className="H3-Dark">Connect Alyce and Dynamics</Typography>
        <Typography component="div" className="Body-Regular-Left-Inactive">
          Please follow these steps to connect your MS Dynamics account with Alyce. Insert your Dynamics CRM subdomain
          following this pattern: https://yourdomain.crm.dynamics.com
        </Typography>
      </Box>
      <Paper elevation={1}>
        <Box p={3}>
          <Grid container mb={3}>
            <Grid item>
              <BaseField
                disabled={isApplicationsLoading}
                sx={{ width: 400, paddingRight: 2 }}
                value={dynamicsAppUrl}
                onChange={e => setDynamicsAppUrl(e.target.value)}
                placeholder="Dynamics CRM URL"
              />
              {!isAppUrlValid && (
                <Typography my={2} color="error">
                  Please enter a valid URL
                </Typography>
              )}
              {isApplicationExist && activeApplicationUrl && (
                <Typography my={2}>Current CRM URL: {activeApplicationUrl}</Typography>
              )}
            </Grid>
            <BaseButton disabled={isApplicationsLoading} onClick={onSyncDynamicsAppUrlClick}>
              Sync App
            </BaseButton>
          </Grid>
        </Box>
      </Paper>
      <Box mt={4} mb={2}>
        <Typography className="H3-Dark">Connect Alyce to Dynamics</Typography>
        <Typography component="div" className="Body-Regular-Left-Inactive">
          Please ensure you have received the required credentials from your admin.
        </Typography>
      </Box>
      <Paper elevation={1}>
        <Box mt={3} mr={2} mb={3} ml={2}>
          <Grid alignItems="center" container>
            <Grid alignItems="flex-start" item xs={6}>
              <Box ml={2}>
                <Grid alignItems="flex-start" container>
                  <Grid item>
                    <Grid alignItems="flex-start" spacing={2} container>
                      <Grid item>
                        <Box mr={3}>{isApplicationConnected ? 'Authorized' : 'Authorization required'}</Box>
                      </Grid>
                      <Grid item>
                        {isApplicationConnected ? (
                          <>
                            <Grid alignItems="flex-start" container>
                              <Grid item>
                                <DashboardIcon icon="check" color="default-salad" />
                              </Grid>
                              <Grid item>
                                <Typography className={classes.connectedLabel}>
                                  Connected - Alyce can send data to Dynamics
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid alignItems="flex-start" container>
                              <Grid item>
                                <DashboardIcon icon="exclamation-circle" color="error" />
                              </Grid>
                              <Grid item>
                                <Typography className={classes.disconnectedLabel}>
                                  Disconnected - Alyce can not send data to Dynamics
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {oAuthConnectedAt && (
                    <Grid item xs={12}>
                      <Typography className="Subcopy-Static">
                        Connected on {datetimePipe(oAuthConnectedAt)} {connectedBy}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid style={{ textAlign: 'right' }} item xs={6}>
              <Box>
                <BaseButton
                  onClick={onDisconnectClick}
                  disabled={isApplicationsLoading || !isApplicationConnected}
                  style={{ marginRight: '12px' }}
                >
                  Disconnect
                </BaseButton>
                <ActionButton
                  onClick={startOauthFlow}
                  disabled={isApplicationsLoading || isApplicationConnected || !isAppUrlValid || oAuthLink === null}
                  height={48}
                  width={210}
                >
                  Authorize connection
                </ActionButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <ModalConfirmationMessage
        title="You have an app created, are you sure you want to create a new one?"
        submitButtonText="Yes"
        cancelButtonText="No"
        isOpen={isNewAppConfirmationOpen}
        width="100%"
        onSubmit={onCreateNewAppConfirmationSubmit}
        onDiscard={onCreateNewAppConfirmationDiscard}
      />

      <ModalConfirmationMessage
        title="Disconnect Alyce from Dynamics?"
        submitButtonText="Disconnect"
        cancelButtonText="Cancel"
        isOpen={oAuthDisconnectPopupShown}
        width="100%"
        onSubmit={onDisconnectAppConfirmSubmit}
        onDiscard={onDisconnectAppConfirmDiscard}
      >
        <Typography className="Body-Regular-Left-Static">
          Alyce will not be able to send any data to Dynamics. You will need to authorize the connection to send new
          data from Alyce to Dynamics.
        </Typography>
      </ModalConfirmationMessage>

      <ModalConfirmationMessage
        title="Connection Error"
        submitButtonText="Ok"
        isOpen={oAuthErrorPopupShown}
        width="100%"
        onSubmit={onErrorConfirmSubmit}
      >
        <Typography className="Body-Regular-Left-Static">
          Connection was not authorized successfully. {oAuthErrorMessage}.
        </Typography>
      </ModalConfirmationMessage>
    </DashboardLayout>
  );
};

OrganisationApplicationsDynamics.propTypes = {
  parentUrl: PropTypes.string.isRequired,
};

export default OrganisationApplicationsDynamics;
