import { createRequestTypes } from '@alycecom/utils';

export const ORGANISATION_APPLICATIONS = createRequestTypes('ORGANISATION_APPLICATIONS');
export const ORGANISATION_APPLICATIONS_CREATE = createRequestTypes('ORGANISATION_APPLICATIONS_CREATE');
export const ORGANISATION_APPLICATIONS_UPDATE = createRequestTypes('ORGANISATION_APPLICATIONS_UPDATE');

export const POLLING_DYNAMICS_ORGANISATION_APPLICATIONS = createRequestTypes(
  'POLLING_DYNAMICS_ORGANISATION_APPLICATIONS',
);
export const DYNAMICS_ORGANISATION_APPLICATIONS = createRequestTypes('DYNAMICS_ORGANISATION_APPLICATIONS');
export const DYNAMICS_ORGANISATION_APPLICATIONS_CREATE = createRequestTypes(
  'DYNAMICS_ORGANISATION_APPLICATIONS_CREATE',
);
