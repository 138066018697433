import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { GiftingFlow, Features } from '@alycecom/modules';
import { WindowScroller } from 'react-virtualized';
import { useDispatch, useSelector } from 'react-redux';

import { EmbeddedCampaignMarketplace } from '../../../MarketplaceModule/components/EmbeddedMarketplace';
import { ProductFilter } from '../../../MarketplaceModule/store/products/products.types';
import { setIsRangeEnabled } from '../../../MarketplaceModule/store/products/products.actions';
import ProductDetailsWithRange from '../../../MarketplaceModule/components/ProductDetailsWithRange/ProductDetailsWithRange';

const styles = {
  scrollArea: {
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    mx: -2,
    px: 2,
  },
  filtersPanel: {
    position: 'sticky',
    top: 0,
    p: 1,
    mx: 2,
    zIndex: 'appBar',
    backgroundColor: 'common.white',
  },
} as const;

const SelectGiftStep = (): JSX.Element => {
  const [scrollableElement, setScrollableElement] = useState<HTMLElement | undefined>();
  const dispatch = useDispatch();
  const [sidebarProduct, setSidebarProduct] = useState(undefined);
  const isMultipleGiftCardValuesEnabled = useSelector(
    Features.selectors.hasFeatureFlag(Features.FLAGS.MULTIPLE_GIFT_CARD_VALUES),
  );

  useEffect(() => {
    if (isMultipleGiftCardValuesEnabled) {
      dispatch(setIsRangeEnabled(true));
    }
    return () => {
      dispatch(setIsRangeEnabled(false));
    };
  }, [dispatch, isMultipleGiftCardValuesEnabled]);

  const handleSelectDefaultGift = useCallback((callback, product) => {
    callback({ id: product.id, denomination: product.denomination?.price ?? null });
    setSidebarProduct(undefined);
  }, []);

  return (
    <Box ref={el => setScrollableElement(el as HTMLElement)} sx={styles.scrollArea}>
      <GiftingFlow.SelectProduct
        renderMarketplace={({ onSelect, campaignId, campaign, value, defaultProductIds }) => (
          <>
            {isMultipleGiftCardValuesEnabled && sidebarProduct && (
              <ProductDetailsWithRange
                onChoose={product => handleSelectDefaultGift(onSelect, product)}
                onClose={() => setSidebarProduct(undefined)}
                isOpen={!!sidebarProduct}
                product={sidebarProduct}
                minPrice={campaign?.budgetSettings?.minPrice ?? null}
                maxPrice={campaign?.budgetSettings?.maxPrice ?? null}
                donationMinPrice={campaign?.budgetSettings?.donationMinPrice ?? null}
                donationMaxPrice={campaign?.budgetSettings?.donationMaxPrice ?? null}
                giftCardMinPrice={campaign?.budgetSettings?.giftCardMinPrice ?? null}
                giftCardMaxPrice={campaign?.budgetSettings?.giftCardMaxPrice ?? null}
              />
            )}
            <WindowScroller scrollElement={scrollableElement}>
              {({ scrollTop, height, registerChild }) => (
                <Box mx={-3}>
                  <EmbeddedCampaignMarketplace
                    initialFilters={{
                      [ProductFilter.HiddenProductIds]: defaultProductIds,
                    }}
                    filtersPanelWrapperProps={{
                      sx: styles.filtersPanel,
                    }}
                    gridProps={{
                      scrollTop,
                      height,
                      registerGridWrapper: registerChild,
                      autoHeight: true,
                    }}
                    campaignId={campaignId}
                    getIsProductSelected={product => !!value && product.id === value.id}
                    onClickProduct={product => {
                      // @ts-ignore
                      setSidebarProduct(product);
                      return onSelect({
                        id: product.id,
                        denomination: product?.denomination?.price,
                        hasRangeDenomination: product?.hasRangeDenomination ?? false,
                      });
                    }}
                  />
                </Box>
              )}
            </WindowScroller>
          </>
        )}
      />
    </Box>
  );
};

export default SelectGiftStep;
