import { Divider } from '@mui/material';
import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

import { getInfluitiveWebhook } from '../../../../../../store/organisation/integrations/influitive/influitive.selectors';

import ConnectAlyceAndInfluitive from './ConnectAlyceAndInfluitive';
import CopyAlyceWebhook from './CopyAlyceWebhook';
import CreateInfluitiveCampaign from './CreateInfluitiveCampaign';

const useStyles = makeStyles(({ spacing }) => ({
  icon: {
    marginRight: spacing(1),
    fontSize: '1rem',
  },
  divider: {
    margin: spacing(5, 0),
  },
}));

const OrganizationIntegrationInfluitive = () => {
  const classes = useStyles();
  const webhook = useSelector(getInfluitiveWebhook);

  return (
    <>
      <ConnectAlyceAndInfluitive />
      {!!webhook.webhookUrl && (
        <>
          <Divider className={classes.divider} color="primary.superLight" />
          <CopyAlyceWebhook />
          <Divider className={classes.divider} color="primary.superLight" />
          <CreateInfluitiveCampaign />
        </>
      )}
    </>
  );
};

export default memo(OrganizationIntegrationInfluitive);
