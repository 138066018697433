import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';

import { filters, TRoiFiltersState } from './filters';

export type TRoiState = {
  filters: TRoiFiltersState;
};

const roiPersistConfig: PersistConfig<TRoiState> = {
  key: 'roi',
  storage,
  version: 1,
  whitelist: ['filters'],
};

export const roiState = combineReducers<TRoiState>({
  filters,
});

export const roi = persistReducer(roiPersistConfig, roiState);
