import { createAsyncAction } from '@alycecom/utils';

import {
  TWorkatoActiveOneToOneCampaigns,
  TWorkatoActiveOneToOneCampaignsFulfilled,
  TWorkatoActiveOneToOneCampaignsIdentifier,
} from './activeOneToOneCampaigns.types';

const PREFIX = 'SETTINGS_MODULE/WORKATO/UTILS';

export const loadWorkatoActiveOneToOneCampaigns = createAsyncAction<
  TWorkatoActiveOneToOneCampaigns & TWorkatoActiveOneToOneCampaignsIdentifier,
  TWorkatoActiveOneToOneCampaignsFulfilled & TWorkatoActiveOneToOneCampaignsIdentifier,
  TWorkatoActiveOneToOneCampaignsIdentifier
>(`${PREFIX}/LOAD_WORKATO_CAMPAIGNS_LIST`);

export const clearWorkatoActiveOneToOneCampaigns = createAsyncAction<TWorkatoActiveOneToOneCampaignsIdentifier>(
  `${PREFIX}/CLEAR_WORKATO_CAMPAIGNS_LIST`,
);
