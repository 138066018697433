export const msDynamicsCreateRecipeConfig = {
  id: 'dev_crm_dynamics_microsoft_dynamics_integration_gift_created',
  title: 'Created Gift Data in Dynamics',
  description: 'This recipe triggers a new gift record and note in Dynamics whenever a gift is created',
} as const;

export const msDynamicsClaimRecipeConfig = {
  id: 'dev_crm_dynamics_microsoft_dynamics_integration_gift_claimed',
  title: 'Claimed Gift Data in Dynamics',
  description: 'This recipe triggers a new gift record and note in Dynamics whenever a gift is claimed',
} as const;

export const msDynamicsViewRecipeConfig = {
  id: 'dev_crm_dynamics_microsoft_dynamics_integration_gift_viewed',
  title: 'Viewed Gift Data in Dynamics',
  description: 'This recipe triggers a new gift record and note in Dynamics whenever a gift is viewed',
} as const;

export const msDynamicsSentRecipeConfig = {
  id: 'dev_crm_dynamics_microsoft_dynamics_integration_gift_sent',
  title: 'Sent Gift Data in Dynamics',
  description: 'This recipe triggers a new gift record and note in Dynamics whenever a gift is sent',
} as const;
