import { createSelector } from 'reselect';
import { StateStatus } from '@alycecom/utils';

import { IRootState } from '../../../../../../../store/root.types';
import { ICampaignListItem } from '../../../../campaigns/campaigns.types';

import { TActiveOneToOneCampaignsState } from './activeOneToOneCampaigns.reducer';

export const getActiveOneToOneCampaignsState = (state: IRootState): TActiveOneToOneCampaignsState =>
  state.settings.organisation.integrations.workato.activeOneToOneCampaigns;

export const getIsLoadingWorkatoAutocompleteOptionsByIdentifier = (
  autocompleteIdentifier: string,
): ((state: IRootState) => boolean) =>
  createSelector(getActiveOneToOneCampaignsState, state =>
    state[autocompleteIdentifier] ? state[autocompleteIdentifier].status === StateStatus.Pending : false,
  );

export const getWorkatoAutocompleteOptionsByIdentifier = (
  autocompleteIdentifier: string,
): ((state: IRootState) => ICampaignListItem[]) =>
  createSelector(getActiveOneToOneCampaignsState, state =>
    state[autocompleteIdentifier] ? state[autocompleteIdentifier].campaigns : [],
  );
