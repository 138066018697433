import { createAsyncAction } from '@alycecom/utils';
import { createAction } from 'redux-act';

import { IInfluitiveCredentials, IInfluitiveWebhook, InfluitiveWebhookErrors } from './influitive.types';

const PREFIX = 'INTEGRATIONS_INFLUITIVE';

export const loadOrganizationInfluitiveIntegration = createAsyncAction<void, IInfluitiveWebhook>(`${PREFIX}_LOAD`);

export const updateOrganizationInfluitiveIntegration = createAsyncAction<
  IInfluitiveCredentials,
  IInfluitiveWebhook,
  InfluitiveWebhookErrors
>(`${PREFIX}_UPDATE`);

export const clearOrganizationInfluitiveWebhook = createAction(`${PREFIX}_CLEAR_WEBHOOK`);
