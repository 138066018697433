// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AlyceTheme, IProductDetails, LoadingLabel, NumberField } from '@alycecom/ui';
import { useDebounce } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMarketplaceConvertedPrice,
  getMarketplaceExchangeIsLoading,
} from '@alycecom/modules/dist/CreateGift/store/marketplace/marketplace.selector';
import { IGiftCardRange } from '@alycecom/ui/dist/components/Marketplace/MarketplaceProduct/MarketplaceProduct';

const useStyles = makeStyles<AlyceTheme>(({ palette }) => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    borderTop: `1px solid ${palette.dividerLight}`,
  },
}));

export interface IProductPriceInputProps {
  product?: IProductDetails & { giftCardRange?: IGiftCardRange; hasRangeDenomination?: boolean };
  onChange: (value: number) => void;
}

type TErrors = {
  [key: string]: Array<string>;
};

const ProductPriceInput = ({ product, onChange }: IProductPriceInputProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const convertedPrice = useSelector(getMarketplaceConvertedPrice);
  const isLoading = useSelector(getMarketplaceExchangeIsLoading);
  const minValue = product?.giftCardRange?.minPrice || 0;
  const maxValue = product?.giftCardRange?.maxPrice || 0;

  useEffect(() => {
    onChange(minValue);
  }, [onChange, minValue]);

  const [localValue, setLocalValue] = useState<number>(minValue);
  const [error, setError] = useState<TErrors>();

  const validate = useCallback(
    (value: number) => {
      if (value < minValue || value > maxValue) {
        setError({
          denomination: [`Should be between ${minValue} and ${maxValue}`],
        });

        return false;
      }

      if (value % 5 !== 0) {
        setError({ denomination: ['Should be a multiple of 5'] });

        return false;
      }

      return true;
    },
    [maxValue, minValue],
  );

  useDebounce(
    () => {
      setError(undefined);
      validate(localValue);
    },
    500,
    [localValue, validate, dispatch],
  );
  const endAdornment = useMemo(() => {
    if (!localValue || product?.giftCardRange?.currencyId === 1) {
      return null;
    }

    return isLoading ? <LoadingLabel width={28} /> : <InputAdornment position="end">${convertedPrice}</InputAdornment>;
  }, [localValue, isLoading, convertedPrice, product]);

  return (
    <Box className={classes.headerWrapper}>
      <NumberField
        name="denomination"
        fullWidth
        max={product?.giftCardRange?.maxPrice}
        min={product?.giftCardRange?.minPrice}
        step={5}
        error={!!error}
        errors={error}
        value={localValue}
        placeholder={`${product?.giftCardRange?.minPrice} - ${product?.giftCardRange?.maxPrice}`}
        InputProps={{
          startAdornment: <InputAdornment position="start">{product?.giftCardRange?.currencySign}</InputAdornment>,
          endAdornment,
        }}
        required
        variant="outlined"
        onChange={newValue => {
          onChange(parseInt(newValue.toString(), 10));
          setLocalValue(parseInt(newValue.toString(), 10));
        }}
      />
    </Box>
  );
};

export default memo<IProductPriceInputProps>(ProductPriceInput);
