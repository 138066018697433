import React, { memo, useCallback, useEffect } from 'react';
import { Security, SettingsItem } from '@alycecom/modules';
import { Box, CircularProgress, FormControlLabel, Grid, Slider, Switch, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@alycecom/ui';
import { useDispatch, useSelector } from 'react-redux';

export enum SliderConfig {
  MinDays = 1,
  MaxDays = 120,
}

const marks = [
  { value: SliderConfig.MinDays, label: '1 d' },
  { value: 20, label: '' },
  { value: 40, label: '' },
  { value: 60, label: '' },
  { value: 80, label: '' },
  { value: 100, label: '' },
  { value: SliderConfig.MaxDays, label: '120 d' },
];

const {
  updateRevenueImpactDashboardCreatedPipeline,
  getRevenueImpactDashboardCreatedPipelineAccess,
  getNumberOfDaysBeforeOpportunityIsCreated,
  getIsSecuritySettingsLoading,
  getIsSecuritySettingsLoaded,
} = Security;

function valuetext(value: number) {
  return `${value} d`;
}

const AlyceCreatedPipeline = (): JSX.Element => {
  const dispatch = useDispatch();
  const { control, reset, handleSubmit } = useForm<{ createdPipeline: boolean; numberOfDaysBeforeOpportunity: number }>(
    {
      mode: 'onChange',
      defaultValues: {
        createdPipeline: true,
        numberOfDaysBeforeOpportunity: 60,
      },
    },
  );
  const isEnabled = useSelector(getRevenueImpactDashboardCreatedPipelineAccess);
  const numberOfDaysBeforeOpportunityIsCreated = useSelector(getNumberOfDaysBeforeOpportunityIsCreated);
  const isSecuritySettingsLoading = useSelector(getIsSecuritySettingsLoading);
  const isSecuritySettingsLoaded = useSelector(getIsSecuritySettingsLoaded);

  const submitHandler = useCallback(
    (formValues: { createdPipeline: boolean; numberOfDaysBeforeOpportunity: number }) => {
      dispatch(
        updateRevenueImpactDashboardCreatedPipeline({
          enabled: formValues.createdPipeline,
          numberOfDaysBeforeOpportunityIsCreated: formValues.numberOfDaysBeforeOpportunity,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (isSecuritySettingsLoaded) {
      reset({
        createdPipeline: isEnabled,
        numberOfDaysBeforeOpportunity: numberOfDaysBeforeOpportunityIsCreated,
      });
    }
  }, [reset, isSecuritySettingsLoaded, numberOfDaysBeforeOpportunityIsCreated, isEnabled]);

  return (
    <SettingsItem
      title="Alyce-First Revenue"
      isLoading={false}
      collapsible
      description={
        <>
          <Typography>
            Show value of opportunities created by Alyce-First gifting, where a gift is sent prior to CRM opportunity
            creation.
          </Typography>
          <Typography display="inline">Currently</Typography>
        </>
      }
      value={isEnabled ? 'On' : 'Off'}
    >
      <form onSubmit={handleSubmit(submitHandler)}>
        <Grid container spacing={2} direction="column">
          <Grid item marginTop={2}>
            <Controller
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  checked={field.value}
                  control={<Switch color="primary" />}
                  label="Alyce-Created Pipeline"
                />
              )}
              name="createdPipeline"
            />
          </Grid>
          <Grid item>
            <Typography fontWeight={700}>Number of days before opportunity is created</Typography>
            <Typography marginTop={1}>
              Manage the number of days for matching door-opener gifts to opportunities.
            </Typography>
            <Typography>
              Number of days:
              <Typography className="Body-Regular-Left-Chambray-Bold" display="inline" marginLeft={1}>
                {numberOfDaysBeforeOpportunityIsCreated}
              </Typography>
            </Typography>
            <Controller
              control={control}
              render={({ field }) => (
                <Box width={300} marginLeft={1} marginTop={1}>
                  <Slider
                    aria-label="Number of days before opportunity is created"
                    getAriaValueText={valuetext}
                    step={1}
                    valueLabelDisplay="auto"
                    marks={marks}
                    min={SliderConfig.MinDays}
                    max={SliderConfig.MaxDays}
                    {...field}
                  />
                </Box>
              )}
              name="numberOfDaysBeforeOpportunity"
            />
          </Grid>
          <Grid item marginTop={1}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={isSecuritySettingsLoading}
              startIcon={isSecuritySettingsLoading && <CircularProgress color="inherit" size={20} />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </SettingsItem>
  );
};

export default memo(AlyceCreatedPipeline);
