import React, { memo } from 'react';

import salesforceIcon from '../../../../../../../assets/images/salesdorce.png';
import { useOpenIntegration } from '../../../../../hooks/useOpenIntegration';
import { useSalesforceIntegration } from '../../../../../hooks/useSalesforceIntegration';
import { IntegrationCard } from '../../IntegrationCard/IntegrationCard';
import { IConfigurableIntegration, Integrations } from '../models/IntegrationsModels';

const description =
  'Alyce for Salesforce is a Salesforce AppExchange certified integration that allows Salesforce users to visualize all of their Alyce gifting details and data within Salesforce as well as send Alyce gifts without leaving their Salesforce instance.';

const SalesforceIntegration = ({ url }: IConfigurableIntegration): JSX.Element => {
  const { integrationStatus, isLoading } = useSalesforceIntegration();
  const selectIntegration = useOpenIntegration(url);

  return (
    <IntegrationCard
      title="Salesforce"
      isLoading={isLoading}
      logoSrc={salesforceIcon}
      description={description}
      status={integrationStatus}
      shouldGoToMarketplace={false}
      open={() => selectIntegration(Integrations.Salesforce)}
    />
  );
};
export default memo(SalesforceIntegration);
