import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getIsLoadingWorkatoAutocompleteOptionsByIdentifier,
  getWorkatoAutocompleteOptionsByIdentifier,
} from '../../../../../../../store/organisation/integrations/workato/activeOneToManyCampaigns/activeOneToManyCampaigns.selectors';
import {
  getIsLoadingWorkatoAutocompleteOptionsByIdentifier as getIsLoadingWorkatoOneToOneAutocompleteOptionsByIdentifier,
  getWorkatoAutocompleteOptionsByIdentifier as getWorkatoOneToOneAutocompleteOptionsByIdentifier,
} from '../../../../../../../store/organisation/integrations/workato/activeOneToOneCampaigns/activeOneToOneCampaigns.selectors';
import { ICampaignListItem } from '../../../../../../../store/campaigns/campaigns.types';

import { TWorkatoAutocompleteOption, TWorkatoAutocompleteValue } from './types';

type TWorkatoAutocompleteOptions = {
  options: TWorkatoAutocompleteOption[];
  selectedValue: TWorkatoAutocompleteValue;
  setSelectedValue: (value: TWorkatoAutocompleteValue) => void;
  isLoadingOptions: boolean;
};

const formOption = (campaign: ICampaignListItem) => ({
  name: campaign.name,
  id: campaign.id,
});

export const useWorkatoAutocompleteOptions = (
  value: string,
  autocompleteIdentifier: string,
  isOneToOne: boolean = false,
): TWorkatoAutocompleteOptions => {
  const [selectedValue, setSelectedValue] = useState<TWorkatoAutocompleteValue>(null);

  const campaigns = useSelector(
    useMemo(
      () =>
        (isOneToOne ? getWorkatoOneToOneAutocompleteOptionsByIdentifier : getWorkatoAutocompleteOptionsByIdentifier)(
          autocompleteIdentifier,
        ),
      [autocompleteIdentifier, isOneToOne],
    ),
  );
  const isLoadingCampaigns = useSelector(
    useMemo(
      () =>
        (isOneToOne
          ? getIsLoadingWorkatoOneToOneAutocompleteOptionsByIdentifier
          : getIsLoadingWorkatoAutocompleteOptionsByIdentifier)(autocompleteIdentifier),
      [autocompleteIdentifier, isOneToOne],
    ),
  );

  useEffect(() => {
    const getDefaultOption = () => campaigns.find(c => c.id.toString() === value) ?? null;
    const targetCampaign = getDefaultOption();
    if (value && targetCampaign) {
      const selectedOption = formOption(targetCampaign);
      setSelectedValue(selectedOption);
    }
  }, [value, campaigns]);

  const options = useMemo(() => campaigns.map(campaign => formOption(campaign)), [campaigns]);

  return { options, selectedValue, setSelectedValue, isLoadingOptions: isLoadingCampaigns };
};
