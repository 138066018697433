import React, { memo, PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface StepToConnectProps {
  id: string;
  title: string;
  description: JSX.Element | string;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  title: {
    fontSize: '1.5rem',
    lineHeight: 1,
    fontWeight: 600,
    margin: 0,
    padding: 0,
    color: palette.text.primary,
  },
  description: {
    marginTop: spacing(1),
    lineHeight: 1.5,
    color: palette.grey.main,
  },
  content: {
    paddingTop: spacing(3),
  },
}));

const StepToConnect = ({ id, children, title, description }: PropsWithChildren<StepToConnectProps>) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.title} data-testid={`${id}.Title`}>
        {title}
      </Typography>
      <Typography className={classes.description} data-testid={`${id}.Description`}>
        {description}
      </Typography>
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};

export default memo(StepToConnect);
