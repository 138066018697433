import { createAction } from 'redux-act';
import { createAsyncAction } from '@alycecom/utils';

import {
  IGiftsBreakdownListItem,
  IGiftsBreakdownListRequestPayload,
  IGiftsBreakdownListSuccessPayload,
} from './giftsBreakdown.types';

const PREFIX = 'GIFTS/BREAKDOWN';

export const fetchGiftsRequest = createAction<IGiftsBreakdownListRequestPayload>(`${PREFIX}/FETCH_GIFTS_REQUEST`);
export const fetchGiftsSuccess = createAction<IGiftsBreakdownListSuccessPayload>(`${PREFIX}/FETCH_GIFTS_SUCCESS`);
export const fetchGiftsFail = createAction(`${PREFIX}/FETCH_GIFTS_FAIL`);

export const toggleSelection = createAction<{ gifts: IGiftsBreakdownListItem[]; checked: boolean }>(
  `${PREFIX}/TOGGLE_SELECTION`,
);

export const resetSelection = createAction(`${PREFIX}/RESET_SELECTION`);

export const disableGifts = createAsyncAction<{ giftIds: number[] }>(`${PREFIX}/DISABLE_GIFTS`);
export const unexpireGifts = createAsyncAction<{ giftIds: number[] }>(`${PREFIX}/UNEXPIRE_GIFTS`);
export const expireGifts = createAsyncAction<{ giftIds: number[] }>(`${PREFIX}/EXPIRE_GIFTS`);
