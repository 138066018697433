import * as yup from 'yup';

import {
  SixthSenseIntegrationField,
  SixthSensePersonalIntegrationField,
  TSixthSenseConfigurationForm,
} from '../../../../../../store/organisation/integrations/workato/picklists/picklists.types';

export const sixthSenseSchema = yup.object().shape({
  [SixthSenseIntegrationField.Segment]: yup
    .string()
    .nullable()
    .default('')
    .trim()
    .required('Please fill out this required field'),
  [SixthSenseIntegrationField.Stage]: yup
    .string()
    .nullable()
    .default('')
    .trim()
    .required('Please fill out this required field'),
  [SixthSenseIntegrationField.JobTitles]: yup
    .string()
    .nullable()
    .default('')
    .trim()
    .required('Please fill out this required field'),
  [SixthSenseIntegrationField.Campaign]: yup
    .string()
    .nullable()
    .default('')
    .trim()
    .required('Please fill out this required field'),
});

export const sixthSensePersonalSchema = yup.object().shape({
  [SixthSensePersonalIntegrationField.Segment]: yup
    .string()
    .nullable()
    .default('')
    .trim()
    .required('Please fill out this required field'),
  [SixthSensePersonalIntegrationField.Stage]: yup
    .string()
    .nullable()
    .default('')
    .trim()
    .required('Please fill out this required field'),
  [SixthSensePersonalIntegrationField.JobTitles]: yup
    .string()
    .nullable()
    .default('')
    .trim()
    .required('Please fill out this required field'),
  [SixthSensePersonalIntegrationField.Campaign]: yup
    .string()
    .nullable()
    .default('')
    .trim()
    .required('Please fill out this required field'),
  [SixthSensePersonalIntegrationField.Email]: yup
    .string()
    .nullable()
    .default('')
    .trim()
    .email()
    .required('Please fill out this required field'),
});

export const sixthSenseSchemaDefaultValues = sixthSenseSchema.getDefault() as TSixthSenseConfigurationForm;
export const sixthSensePersonalSchemaDefaultValues = sixthSensePersonalSchema.getDefault() as TSixthSenseConfigurationForm;
