import { omit, pipe } from 'ramda';
import { createSelector } from 'reselect';
import { pickNotEmpty } from '@alycecom/utils';
import { RowLimit } from '@alycecom/ui';

import { IRootState } from '../../../../../../store/root.types';

import { IGiftsFilters } from './filters.types';

export const getGiftsFiltersState = (state: IRootState): IGiftsFilters => state.dashboard.breakdowns.gifts.giftsFilters;

export const getGiftsFiltersStateWithoutStatus = createSelector(getGiftsFiltersState, state =>
  omit(['giftsState', 'filtersData', 'isSidebarOpened'], state),
);

export const getFilterDataState = pipe(getGiftsFiltersState, state => state.filtersData);

export const getCampaignFilterData = pipe(getFilterDataState, state => state.campaigns);

export const getSentByFilterData = pipe(getFilterDataState, state => state.sentBy);

export const getTeamsFilterData = pipe(getFilterDataState, state => state.teams);

export const getIsFilterSidebarOpened = pipe(getGiftsFiltersState, state => state.isSidebarOpened);

export const getStatusFilter = pipe(getGiftsFiltersState, state => state.giftsState);

export const getSearchFilter = pipe(getGiftsFiltersState, state => state.search);

export const getTeamIdsFilter = pipe(getGiftsFiltersState, state => state.teamIds);

export const getSortFieldFilter = pipe(getGiftsFiltersState, state => state.sortField);

export const getSortDirectionFilter = pipe(getGiftsFiltersState, state => state.sortDirection);

export const getCurrentPageFilter = pipe(getGiftsFiltersState, state => state.currentPage);

export const getLimitFilter = pipe(getGiftsFiltersState, state => state.limit);

export const getDateFromFilter = pipe(getGiftsFiltersState, state => state.dateFrom);

export const getDateToFilter = pipe(getGiftsFiltersState, state => state.dateTo);

export const getCampaignIdsFilter = pipe(getGiftsFiltersState, state => state.campaignIds);

export const getCountryIdsFilter = pipe(getGiftsFiltersState, state => state.countryIds);

export const getSentByIdsFilter = pipe(getGiftsFiltersState, state => state.sentByIds);

export const getSentAsIdsFilter = pipe(getGiftsFiltersState, state => state.sentAsIds);

export const getExpireDaysFilter = pipe(getGiftsFiltersState, state => state.expireDays);

export const getRecipientSearchFilter = pipe(getGiftsFiltersState, state => state.recipientSearch);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getFiltersAsPayload = (state: IRootState) => {
  const giftsState = getStatusFilter(state);
  const search = getSearchFilter(state);
  const teamIds = getTeamIdsFilter(state);
  const sortField = getSortFieldFilter(state);
  const sortDirection = getSortDirectionFilter(state);
  const currentPage = getCurrentPageFilter(state);
  const limit = getLimitFilter(state);
  const dateFrom = getDateFromFilter(state);
  const dateTo = getDateToFilter(state);
  const campaignIds = getCampaignIdsFilter(state);
  const sentByIds = getSentByIdsFilter(state);
  const sentAsIds = getSentAsIdsFilter(state);
  const expireDays = getExpireDaysFilter(state);
  const recipientSearch = getRecipientSearchFilter(state);
  const countryIds = getCountryIdsFilter(state);
  const currentPageValue = currentPage || 1;
  const limitValue = limit || RowLimit.Limit10;
  return pickNotEmpty({
    giftsState,
    search,
    teamIds: teamIds?.join(','),
    campaignIds: campaignIds?.join(','),
    sentByIds: sentByIds?.join(','),
    orderColumn: sortField,
    orderDirection: sortDirection,
    limit: limitValue,
    offset: limitValue * (currentPageValue - 1),
    dateFrom,
    dateTo,
    expireDays,
    recipientSearch,
    sentAsIds: sentAsIds?.join(','),
    countryIds: countryIds?.join(','),
  });
};
