import { createReducer } from 'redux-act';
import { StateStatus } from '@alycecom/utils';

import { IInfluitiveCreateCampaignErrors, InfluitiveCampaignResponse } from '../types/Influitive.types';

import { createInfluitiveCampaignAction } from './influitive.actions';

export interface IInfluitiveState {
  status: StateStatus;
  errors: IInfluitiveCreateCampaignErrors;
  campaign: InfluitiveCampaignResponse | null;
}

const initialState: IInfluitiveState = {
  status: StateStatus.Idle,
  errors: {},
  campaign: null,
};

export const influitive = createReducer({}, initialState);

influitive
  .on(createInfluitiveCampaignAction.pending, state => ({
    ...state,
    status: StateStatus.Pending,
    errors: {},
  }))
  .on(createInfluitiveCampaignAction.fulfilled, (state, payload) => ({
    ...state,
    status: StateStatus.Fulfilled,
    campaign: payload,
    errors: {},
  }))
  .on(createInfluitiveCampaignAction.rejected, (state, payload) => ({
    ...state,
    status: StateStatus.Rejected,
    campaign: null,
    errors: payload,
  }));
