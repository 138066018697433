import { NEVER, merge, of, timer } from 'rxjs';
import { Epic } from 'redux-observable';
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { handleError, handlers, IResponse } from '@alycecom/services';
import { ofType } from '@alycecom/utils';

import {
  disconnectFromOAuth,
  loadOAuthState,
  loadOAuthStateError,
  loadOAuthStateSuccess,
  loadOauthStatePolling,
  startLoadOauthFlowLink,
  successLoadOAuthFlowLink,
} from './dynamics.actions';
import { ConnectionState, DynamicsStartConnectionResponse, IOAuthStateResponse } from './dynamics.types';

export const dynamicsOAuthStatePollingEpic: Epic = (action$, state$, { dynamicsApiService }) =>
  action$.pipe(
    ofType(loadOauthStatePolling),
    mergeMap(() =>
      timer(0, 2000).pipe(
        mergeMap(() =>
          dynamicsApiService.get('/dynamics/oauth/state', null, true).pipe(
            switchMap((response: IResponse<IOAuthStateResponse>) => {
              if (response.data.state === ConnectionState.Disconnected) {
                return NEVER;
              }
              return of(response);
            }),
            map((response: IResponse<IOAuthStateResponse>) => loadOAuthStateSuccess(response)),
            catchError(handleError(handlers.handleAnyError(loadOAuthStateError))),
          ),
        ),
        takeUntil(merge(action$.ofType(loadOAuthStateSuccess, loadOAuthStateError), timer(16000))),
      ),
    ),
  );

export const dynamicsOAuthStateEpic: Epic = (action$, state$, { dynamicsApiService }) =>
  action$.pipe(
    ofType(loadOAuthState),
    mergeMap(() =>
      dynamicsApiService.get('/dynamics/oauth/state', null, true).pipe(
        map((response: IResponse<IOAuthStateResponse>) => loadOAuthStateSuccess(response)),
        catchError(handleError(handlers.handleAnyError(loadOAuthStateError))),
        takeUntil(action$.ofType(loadOAuthState)),
      ),
    ),
  );

export const dynamicsDisconnectOAuthEpic: Epic = (action$, state$, { dynamicsApiService }) =>
  action$.pipe(
    ofType(disconnectFromOAuth),
    mergeMap(() =>
      dynamicsApiService.delete('/dynamics/oauth', null, true).pipe(
        map((response: IResponse<IOAuthStateResponse>) => loadOAuthStateSuccess(response)),
        catchError(handleError(handlers.handleAnyError(loadOAuthStateError))),
        takeUntil(action$.ofType(disconnectFromOAuth)),
      ),
    ),
  );

export const dynamicsOAuthLinkEpic: Epic = (action$, state$, { dynamicsApiService }) =>
  action$.pipe(
    ofType(startLoadOauthFlowLink),
    mergeMap(() =>
      dynamicsApiService.get('/dynamics/oauth/url', null, true).pipe(
        map((response: DynamicsStartConnectionResponse) => successLoadOAuthFlowLink(response.link)),
        catchError(handleError(handlers.handleAnyError(loadOAuthStateError))),
        takeUntil(action$.ofType(startLoadOauthFlowLink)),
      ),
    ),
  );

export const dynamicsOauthEpics = [
  dynamicsOAuthStateEpic,
  dynamicsDisconnectOAuthEpic,
  dynamicsOAuthLinkEpic,
  dynamicsOAuthStatePollingEpic,
];
