import { createReducer } from 'redux-act';

import {
  loadOAuthState,
  loadOAuthStateError,
  loadOAuthStateSuccess,
  startLoadOauthFlowLink,
  successLoadOAuthFlowLink,
} from './dynamics.actions';
import { ConnectionState } from './dynamics.types';

export interface IDynamicsOauthState {
  state: ConnectionState;
  isLoading: boolean;
  message: string | null;
  dynamicsOAuthLink: string | null;
  connectedBy: string | null;
  connectedAt: string | null;
}

export const initialState: IDynamicsOauthState = {
  state: ConnectionState.NotCreated,
  isLoading: false,
  message: null,
  connectedBy: null,
  dynamicsOAuthLink: null,
  connectedAt: null,
};

const reducer = createReducer({}, initialState);

reducer.on(loadOAuthState, state => ({
  ...state,
  isLoading: true,
}));

reducer.on(loadOAuthStateError, state => ({
  ...state,
  isLoading: false,
}));

reducer.on(loadOAuthStateSuccess, (state, { data }) => ({
  ...state,
  state: data.state,
  connectedBy: data.connectedBy,
  connectedAt: data.connectedAt,
  isLoading: false,
}));

reducer.on(startLoadOauthFlowLink, state => ({
  ...state,
  isLoading: true,
}));

reducer.on(successLoadOAuthFlowLink, (state, link) => ({ ...state, dynamicsOAuthLink: link }));

export default reducer;
